import Component from '../../component_container/models/component';
import ComponentError from '../../component_container/models/component_error';
import { TimeDuration } from 'typed-duration';
import ElementData from './element_data';
import ControlledPositionElement from '../../../ui/components/overlay/controlled_position_element';
import UnityComponent from '../unity/unity_component';
import React, { ReactNode } from 'react';
import { CurrencyType } from '../../../ui/components/currency_icon';
import AmountGainedFadeOut from '../../../ui/components/overlay/amount_gained_fade_out';
import ValueContainer from '../../../utils/value_container';
import { toast } from 'react-toastify';
import ContainerHelper from '../../component_container/utilities/container_helper';
import PopulationComponent from '../population/population_component';
import ComponentContainer from '../../component_container/component_container';
import LocationComponent from '../location/location_component';
import SettingsComponent from '../settings/settings_component';
import TooFarAwayFadeOut from '../../../ui/components/overlay/too_far_away_fade_out';
import UnityCameraMode from '../unity/unity_camera_mode';
import MapBubble from '../../../apis/models/map_bubble';

type OverlayComponentSubscriber = (elements: React.JSX.Element[]) => void;
type OnBubbleTouchListener = (bubble: MapBubble) => void;

class OverlayComponent extends Component {
    private _focusedBubble: MapBubble | undefined;
    get focusedBubble(): MapBubble | undefined {
        return this._focusedBubble;
    }

    private _unityComponent: UnityComponent | undefined;
    private _populationComponent: PopulationComponent | undefined;
    private _locationComponent: LocationComponent | undefined;
    private _settingsComponent: SettingsComponent | undefined;

    private _subscribers: OverlayComponentSubscriber[] = [];
    private _onBubbleTouchListeners: OnBubbleTouchListener[] = [];

    addSubscriber(subscriber: OverlayComponentSubscriber): void {
        this._subscribers.push(subscriber);
    }

    removeSubscriber(subscriber: OverlayComponentSubscriber): void {
        this._subscribers = this._subscribers.filter((s) => s !== subscriber);
    }

    addOnBubbleTouchListener(listener: OnBubbleTouchListener): void {
        this._onBubbleTouchListeners.push(listener);
    }

    removeOnBubbleTouchListener(listener: OnBubbleTouchListener): void {
        this._onBubbleTouchListeners = this._onBubbleTouchListeners.filter(
            (l) => l !== listener
        );
    }

    private _notifyOnBubbleTouchListeners(bubble: MapBubble): void {
        this._onBubbleTouchListeners.forEach((listener) => listener(bubble));
    }

    private _notifySubscribers(): void {
        this._elements = this._mapElements();
        this._subscribers.forEach((subscriber) => subscriber(this._elements));
    }

    private _elementData: ElementData[] = [];
    private _elements: React.JSX.Element[] = [];

    get elements(): React.JSX.Element[] {
        return this._elements;
    }

    async addElement(
        gameObject: string,
        children: ReactNode,
        lifetime: number | undefined,
        bottomOffset: number | undefined
    ): Promise<void> {
        const id = Math.random().toString(36).substring(7);
        const ref = React.createRef<{
            setPosition: (x: number, y: number) => void;
            triggerFadeOut: () => void;
        }>();

        const screenPosition = await this._getScreenPosition(
            gameObject,
            'top',
            0
        );

        const element = new ElementData(
            id,
            children,
            ref,
            Date.now(),
            lifetime,
            gameObject,
            { x: screenPosition[0], y: screenPosition[1] },
            bottomOffset
        );
        this._elementData.push(element);
        this._notifySubscribers();
    }

    private _mapElements() {
        return this._elementData.map((element) => (
            <ControlledPositionElement
                key={element.id}
                ref={element.ref}
                children={element.children}
                initialPosition={element.initialPosition}
                bottomOffset={element.bottomOffset || 50}
            />
        ));
    }

    private _coinsObservableListener(
        oldValue: number | undefined,
        newValue: number
    ) {
        if (oldValue === undefined) {
            return;
        }
        const amount = newValue - oldValue;
        if (amount > 0) {
            this.addElement(
                'Character',
                <AmountGainedFadeOut
                    amount={amount}
                    currencyType={CurrencyType.Coin}
                />,
                2000,
                1
            );
        }
    }

    async load(): Promise<Array<ComponentError>> {
        await this.setDependencyLocked([UnityComponent]);
        this._unityComponent = await this.getComponent(UnityComponent).then(
            (component) => component as UnityComponent
        );

        ValueContainer.coinsObservable.addListener(
            this._coinsObservableListener.bind(this)
        );

        ContainerHelper.getSignalRComponent().then((signalRComponent) => {
            signalRComponent.registerMethodHandler(
                'ReceiveNotification',
                this._receiveNotificationMethodHandler.bind(this)
            );
        });

        ComponentContainer.instance!.makeSureLoaded.then(async () => {
            this._populationComponent = await this.getComponent(
                PopulationComponent
            ).then((component) => component as PopulationComponent);
            this._locationComponent = await this.getComponent(
                LocationComponent
            ).then((component) => component as LocationComponent);
            this._settingsComponent = await this.getComponent(
                SettingsComponent
            ).then((component) => component as SettingsComponent);

            this._unityComponent!.addEventListener(
                this._unityTouchEventListener.bind(this)
            );

            this._populationComponent!.addComponentObserver(
                this._populationComponentObserver.bind(this)
            );
        });

        return [];
    }

    private _populationComponentObserver() {
        if (!this._focusedBubble) {
            return;
        }

        const bubble = this._populationComponent!.getBubble(
            this._focusedBubble.id
        );

        if (bubble) {
            return;
        }

        this._focusedBubble = undefined;
        this._notifyObservers(undefined);
    }

    private _unityTouchEventListener(event: string, args: any[]) {
        if (event === 'gameObject:touch:event') {
            const gameObject = args[0] as string;
            const id = Number(gameObject);
            if (isNaN(id)) {
                return;
            }

            const bubble = this._populationComponent!.getBubble(id);

            if (!bubble) {
                return;
            }

            const lastPosition = this._locationComponent!.lastPosition!;
            // check distance to bubble
            const distance = LocationComponent.getDistanceBetweenTwoPoints(
                lastPosition.coords.latitude,
                lastPosition.coords.longitude,
                bubble.latitude,
                bubble.longitude
            );
            const bubbleRange =
                this._settingsComponent!.getDoubleFromClientSettings(
                    'BubbleRange',
                    99
                );

            if (distance > bubbleRange) {
                this.addElement(gameObject, <TooFarAwayFadeOut />, 2000, 0);
                return;
            }

            this._notifyOnBubbleTouchListeners(bubble);

            if (this._focusedBubble !== bubble) {
                this._focusedBubble = bubble;
                this._notifyObservers(undefined);
            }

            this._unityComponent!.setCameraMode(
                UnityCameraMode.FOCUS,
                gameObject
            );
        }
    }

    private async _getScreenPosition(
        gameObject: string,
        position: string,
        offset: number
    ): Promise<[number, number]> {
        if (!this._unityComponent) {
            throw new Error('Unity component not loaded');
        }

        let screenPos = await this._unityComponent.getObjectScreenPosition(
            gameObject,
            position,
            offset
        );

        screenPos[0] = screenPos[0] / window.devicePixelRatio;
        screenPos[1] = screenPos[1] / window.devicePixelRatio;

        return screenPos;
    }

    get name(): string {
        return 'Overlay Component';
    }

    async onPause(): Promise<void> {}

    async onResume(): Promise<void> {}

    async onUnload(): Promise<void> {
        ValueContainer.coinsObservable.removeListener(
            this._coinsObservableListener.bind(this)
        );

        ContainerHelper.getSignalRComponent().then((signalRComponent) => {
            signalRComponent.unregisterMethodHandler(
                'ReceiveNotification',
                this._receiveNotificationMethodHandler.bind(this)
            );
        });

        this._unityComponent!.removeEventListener(
            this._unityTouchEventListener.bind(this)
        );

        this._populationComponent!.removeComponentObserver(
            this._populationComponentObserver.bind(this)
        );
    }

    get type(): Function {
        return OverlayComponent;
    }

    private _receiveNotificationMethodHandler(...args: any[]) {
        const text = args[0] as string;
        toast(text);
    }

    update(sinceLastUpdate: TimeDuration): void {
        this._elementData.forEach((element) => {
            if (element.gameObject) {
                // check if lifetime is set and if it has expired
                // use date.now - created date
                // if expired, trigger fade out and remove from array
                if (
                    element.lifetime &&
                    Date.now() - element.created > element.lifetime
                ) {
                    if (element.ref.current) {
                        element.ref.current.triggerFadeOut();
                    }

                    // check if more than 5000ms has passed since lifetime expired
                    // if so, remove element from array
                    if (
                        Date.now() - element.created >
                        element.lifetime + 5000
                    ) {
                        this._elementData = this._elementData.filter(
                            (e) => e.id !== element.id
                        );
                        this._notifySubscribers();
                    }
                    return;
                }

                this._getScreenPosition(element.gameObject, 'top', 0).then(
                    (position) => {
                        if (element.ref.current) {
                            element.ref.current.setPosition(
                                position[0],
                                position[1]
                            );
                        }
                    }
                );
            }
        });
    }
}

export default OverlayComponent;
