import React, { useState, useImperativeHandle, forwardRef } from 'react';

interface PositionController {
    setPosition: (x: number, y: number) => void;
    triggerFadeOut: () => void;
}

interface ControlledPositionElementProps {
    children?: React.ReactNode;
    style?: React.CSSProperties;
    initialPosition?: { x: number; y: number };
    fadeInDuration?: number; // Duration for fade-in (ms)
    fadeOutDuration?: number; // Duration for fade-out (ms)
    moveUpDistance?: number; // Distance to move up during fade-out (pixels)
    bottomOffset?: number; // Offset from bottom (pixels)
}

const ControlledPositionElement = forwardRef<
    PositionController,
    ControlledPositionElementProps
>(
    (
        {
            children,
            style,
            initialPosition,
            fadeInDuration = 1000,
            fadeOutDuration = 1000,
            moveUpDistance = 30,
            bottomOffset = 1,
        },
        ref
    ) => {
        const [position, setPosition] = useState(
            initialPosition || { x: 0, y: 0 }
        );
        const [opacity, setOpacity] = useState(0);
        const [translateY, setTranslateY] = useState(0);
        const [isFirstRender, setIsFirstRender] = useState(true);
        const [isFadingOut, setIsFadingOut] = useState(false);

        useImperativeHandle(ref, () => ({
            setPosition: (x, y) => {
                setPosition({ x, y });
                if (isFirstRender) {
                    setOpacity(1);
                    setIsFirstRender(false);
                }
                setTranslateY(0); // Reset translateY
            },
            triggerFadeOut: () => {
                if (isFadingOut) return;
                setOpacity(0);
                setTranslateY(-moveUpDistance);
                setIsFadingOut(true);
            },
        }));

        return (
            <div
                style={{
                    position: 'absolute',
                    left: `${position.x}px`,
                    bottom: `calc(${position.y}px - ${0.145 * window.innerHeight}px)`, // TODO: No idea why this is needed
                    // bottom: `${position.y}px`,
                    transform: `translate(-50%, ${translateY}px)`,
                    opacity: opacity,
                    transition: `opacity ${fadeOutDuration}ms ease, transform ${fadeOutDuration}ms ease, left 500ms ease, bottom 500ms ease`,
                    ...style,
                }}
            >
                {children}
            </div>
        );
    }
);

export default ControlledPositionElement;
