import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './dig_window.module.css';
import ParticlesComponent from './particles';
import tokenImg from '../../../assets/images/icons/token-icon.png';

const DigWindow = () => {
    
    const [currentState, setCurrentState] = useState<number>(0);
    const [showOre, setShowOre] = useState<boolean>(true);
    const [progress, setProgress] = useState<number>(0);
    const progressThreshold = 2;
    const progressDecayRate = 0.5;
    const digWindowRef = useRef<HTMLDivElement>(null);
    const oreHolderRef = useRef<HTMLDivElement>(null);
    const oreImageCount = 7;

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prev) => Math.max(prev - progressDecayRate, 0));
        }, 500);

        return () => clearInterval(interval);
    }, []);

    const playTapSound = useCallback(() => {
        const tapSound = new Audio(require('../../../assets/sounds/dig.wav'));
        tapSound.play();
    }, []);

    const handleClick = useCallback(
        (_event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (showOre) {
                oreHolderRef.current?.classList.add(styles.oreHolderTapped);
                digWindowRef.current?.classList.add(styles.tapped);
                setTimeout(() => {
                    oreHolderRef.current?.classList.remove(
                        styles.oreHolderTapped
                    );
                    digWindowRef.current?.classList.remove(styles.tapped);
                }, 100);

                playTapSound();

                setProgress((prev) => prev + 1);

                if (progress + 1 >= progressThreshold) {
                    setProgress(0);
                    setCurrentState((prev) => {
                        if (prev >= oreImageCount - 1) {
                            // Show reward screen when stone is completely cracked
                            setShowOre(false);
                            showRewardScreen();
                            return prev;
                        } else {
                            return prev + 1;
                        }
                    });
                }
            }
        },
        [playTapSound, progress, progressThreshold, currentState]
    );

    const showRewardScreen = () => {
        
    };



    function handleClaimAction(): void {
        console.log('Claiming reward');
    }

    return (
        <div
            className={styles.digWindow}
            ref={digWindowRef}
            onClick={handleClick}
        >
             {!showOre && (
            <div id="reward" className={styles.rewardHolder}>
                <img src={tokenImg} className={styles.rewardImg} alt="" />
                <h3 className={styles.rewardText}>
                    <small className={styles.rewardTextSmall}>You dug up</small>
                    <br />
                    2.4k BONK
                </h3>
                <button className={styles.closeButton} name="button" 
                    onClick={()=>handleClaimAction()}>
                    Claim
                </button>
            </div>
             )}
             {showOre && (
                <>
                
                    <img
                        src={require('../../../assets/images/panels/mine-banner.png')}
                        className={styles.banner}
                        alt=""
                    />
                    <div className={styles.oreHolder} ref={oreHolderRef}>
                        <img
                            src={require(
                                `../../../assets/images/stone_states/stone_green_${currentState}.png`
                            )}
                            className={`${styles.ore} ${styles[`state_${currentState}`]}`}
                            style={{ display: showOre ? 'block' : 'none' }}
                            alt=""
                        />
                    </div>
                
                    
                        <div className={styles.textHolder}>
                            <p className={styles.digText}>TAP TO DIG</p>
                        </div>
                    
                    <ParticlesComponent />
                </>
            )}
            <div className={styles.spinnerBackground}></div>
            <img
                src={require('../../../assets/images/backgrounds/background-ani.png')}
                className={styles.animatedImage}
                alt=""
            />
        </div>
    );
};

export default DigWindow;
