class Task {
    taskId: string;
    userId: string;
    taskType: string;
    title: string;
    description: string;
    points: number;
    tokens: number | null;
    url: string | null;
    startedAt: Date | null;
    completedAt: Date | null;
    status: string;
    pointsEarned: number;
    tokensEarned: number | null;
    tokenName: string | null;
    tokenImage: string | null;
    verified: boolean;

    constructor({
        taskId,
        userId,
        taskType,
        title,
        description,
        points, 
        tokens,
        url,
        startedAt,
        completedAt,
        status,
        pointsEarned,
        tokensEarned,
        tokenName,
        tokenImage,
        verified,
    }: {
        taskId: string;
        userId: string;
        taskType: string;
        title: string;
        description: string;
        points: number;
        tokens: number | null;
        url: string | null;
        startedAt: Date | null;
        completedAt: Date | null;
        status: string;
        pointsEarned: number;
        tokensEarned: number | null;
        tokenName: string | null;
        tokenImage: string | null;
        verified: boolean;
    }) {
        this.taskId = taskId;
        this.userId = userId;
        this.taskType = taskType;
        this.title = title;
        this.description = description;
        this.points = points;
        this.tokens = tokens;
        this.url = url;
        this.startedAt = startedAt ? new Date(startedAt) : null;
        this.completedAt = completedAt ? new Date(completedAt) : null;
        this.status = status;
        this.pointsEarned = pointsEarned;
        this.tokensEarned = tokensEarned;
        this.tokenName = tokenName;
        this.tokenImage = tokenImage
        this.verified = verified;
    }

    static fromJsonLowerCamelCase(json: any): Task {
        return new Task({
            taskId: json.taskId,
            userId: json.userId,
            taskType: json.taskType,
            title: json.title,
            description: json.description,
            points: json.points,
            tokens: json.tokens,
            url: json.url,
            startedAt: json.startedAt ? new Date(json.startedAt) : null,
            completedAt: json.completedAt ? new Date(json.completedAt) : null,
            status: json.status,
            pointsEarned: json.pointsEarned,
            tokensEarned: json.tokensEarned,
            tokenName: json.tokenName,
            tokenImage: json.tokenImage,
            verified: json.verified,
        });
    }

    static fromJsonUpperCase(json: any): Task {
        return new Task({
            taskId: json.TaskId,
            userId: json.UserId,
            taskType: json.TaskType,
            title: json.Title,
            description: json.Description,
            points: json.Points,
            tokens: json.Tokens,
            url: json.Url,
            startedAt: json.StartedAt ? new Date(json.StartedAt) : null,
            completedAt: json.CompletedAt ? new Date(json.CompletedAt) : null,
            status: json.Status,
            pointsEarned: json.PointsEarned,
            tokensEarned: json.TokensEarned,
            tokenName: json.TokenName,
            tokenImage: json.TokenImage,
            verified: json.Verified,
        });
    }
}

export default Task;