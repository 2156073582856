class TaskCompletion {
    taskId: string;
    status: string;
    completedAt: Date;
    pointsEarned: number;
    tokensEarned: number | null;


    constructor({
        taskId,
        status,
        completedAt,
        pointsEarned,
        tokensEarned,
    }: {
        taskId: string;     
        status: string;
        completedAt: Date;
        pointsEarned: number;
        tokensEarned: number | null;

    }) {
        this.taskId = taskId;     
        this.status = status;
        this.completedAt = new Date(completedAt);
        this.pointsEarned = pointsEarned;
        this.tokensEarned = tokensEarned;

    }

    static fromJsonLowerCamelCase(json: any): TaskCompletion {
        return new TaskCompletion({
            taskId: json.taskId,
            status: json.status,
            completedAt: new Date(json.completedAt),
            pointsEarned: json.pointsEarned,
            tokensEarned: json.tokensEarned,
        });
    }

    static fromJsonUpperCase(json: any): TaskCompletion {
        return new TaskCompletion({
            taskId: json.TaskId,         
            status: json.Status,
            completedAt: new Date(json.CompletedAt),
            pointsEarned: json.PointsEarned,
            tokensEarned: json.TokensEarned,
        });
    }
}

export default TaskCompletion;