import { useState, useEffect } from 'react';
import Component from '../models/component';

function useFetchComponent<T extends Component>(
    fetchComponent: () => Promise<T>
): T | undefined {
    const [component, setComponent] = useState<T | undefined>(undefined);
    const [, setForceUpdate] = useState(0); // To trigger re-renders

    useEffect(() => {
        let mounted = true;

        const updateState = (_state: any) => {
            if (mounted) {
                setForceUpdate((prev) => prev + 1); // Trigger a re-render
            }
        };

        fetchComponent()
            .then((fetchedComponent) => {
                if (mounted) {
                    setComponent(fetchedComponent);
                    fetchedComponent.addComponentObserver(updateState);
                }
            })
            .catch(console.error);

        return () => {
            mounted = false;
            if (component) {
                component.removeComponentObserver(updateState);
            }
        };
    }, [fetchComponent]);

    return component;
}

export default useFetchComponent;
