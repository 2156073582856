import { constants } from '../../../utils/constants';

const AVATAR_URL_STRUCTURE = constants.AVATAR_URL_STRUCTURE;

class Friend {
    private readonly _phoneNumber?: string;
    private readonly _username?: string;
    private readonly _telegramId?: string;
    private readonly _avatarUrl?: string;

    constructor(phoneNumber?: string, username?: string, telegramId?: string) {
        this._phoneNumber = phoneNumber;
        this._username = username;
        this._telegramId = telegramId;
        this._avatarUrl = username
            ? AVATAR_URL_STRUCTURE.replace('%USERNAME%', telegramId!)
            : undefined;
    }

    get phoneNumber(): string | undefined {
        return this._phoneNumber;
    }

    get username(): string | undefined {
        return this._username;
    }

    get avatarUrl(): string | undefined {
        return this._avatarUrl;
    }

    get telegramId(): string | undefined {
        return this._telegramId;
    }

    static fromJsonLowerCamelCase(json: any): Friend {
        return new Friend(json.phoneNumber, json.username, json.telegramId);
    }

    static fromJsonUpperCase(json: any): Friend {
        return new Friend(json.PhoneNumber, json.Username, json.TelegramId);
    }
}

export default Friend;
