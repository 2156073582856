import { constants } from "../../utils/constants";
import ValueContainer from "../../utils/value_container";
import Referral from "../models/task/referral";
import Task from "../models/task/task";
import TaskCompletion from "../models/task/task_completed";
import StandardHttpAPI from "../standard_http_api";

const API_URL = constants.API_URL;

class QuestApi {

    static async getTasks(): Promise<Task[]> {
        const response = await StandardHttpAPI.get({
            uri: `${API_URL}tasks`,
            bearer: ValueContainer.token,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return response.response.map((json: any) =>
            Task.fromJsonLowerCamelCase(json)
        );
    }
    
    static async verifyTask(taskId: string): Promise<TaskCompletion> {
        const response = await StandardHttpAPI.put({
            uri: `${API_URL}tasks/${taskId}/verify`,
            bearer: ValueContainer.token,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return TaskCompletion.fromJsonLowerCamelCase(response.response);
    }

    static async getReferrals(): Promise<Referral[]> {
        const response = await StandardHttpAPI.get({
            uri: `${API_URL}referrals`,
            bearer: ValueContainer.token,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return response.response.map((json: any) =>
            Referral.fromJsonLowerCamelCase(json)
        );
    }

}

export default QuestApi;
