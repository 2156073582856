import { TimeDuration } from "typed-duration";
import Component from "../../component_container/models/component";
import ComponentError from "../../component_container/models/component_error";
import ComponentErrorType from '../../component_container/enums/component_error_type';
import AuthenticationComponent from '../authentication/authentication_component';
import Task from '../../../apis/models/task/task';
import QuestApi from '../../../apis/quest_api/quest_api';
import SignalRComponent from "../signalr/signalr_component";
import ContainerHelper from "../../component_container/utilities/container_helper";
import SignalRConnected from "../signalr/signalr_connected";
import TaskCompletion from "../../../apis/models/task/task_completed";
import Referral from "../../../apis/models/task/referral";
import StandardHttpResponse from "../../../apis/models/standard_http_response";
import { toast } from "react-toastify";
import TaskStatus from "../../../apis/models/task/task_status";

type QuestComponentSubscriber = () => void;

class QuestComponent extends Component {
    private _tasks: Task[] = [];
    private _referrals: Referral[] = [];

    private _subscribers: QuestComponentSubscriber[] = [];
    private _reFetching: boolean = false;

    get tasks(): Task[] {
        return this._tasks;
    }

    get referrals(): Referral[] {
        return this._referrals;
    }

    get referralsCount(): number {
        return this._referrals.length;
    }

    get referralPointsEarned (): number {
        return this._referrals.reduce((acc, referral) => acc + referral.pointsEarned, 0);
    }

    addSubscriber(subscriber: QuestComponentSubscriber): void {
        this._subscribers.push(subscriber);
    }

    removeSubscriber(subscriber: QuestComponentSubscriber): void {
        this._subscribers = this._subscribers.filter(
            (existingSubscriber) => existingSubscriber !== subscriber
        );
    }

    private _notifySubscribers(): void {
        this._subscribers.forEach((subscriber) => subscriber());
    }
    
    private async _initValues(): Promise<boolean> {
        try {
            this._tasks = await QuestApi.getTasks();
            this._referrals = await QuestApi.getReferrals();

            this._notifySubscribers();
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    get type(): Function {
        return QuestComponent;
    }
    
    get name(): string {
        return 'Quest Component';
    }
    private _taskCompletedMethodHandler(...args: any[]) {
        const taskCompleted = TaskCompletion.fromJsonUpperCase(args[0]);
        this._tasks =  this._tasks.map((task) => {
            if (task.taskId === taskCompleted.taskId) {
                // Update the matching task with new data
                return new Task({
                    ...task,
                    completedAt: taskCompleted.completedAt,
                    pointsEarned: taskCompleted.pointsEarned,
                    tokensEarned: taskCompleted.tokensEarned,
                    status: TaskStatus.Completed,
                    verified: true,
                });
            }
            return task; // Leave other tasks unchanged
        });

        this._notifySubscribers();
    }

    private _referralCompletedMethodHandler(...args: any[]) {
        const referral = Referral.fromJsonUpperCase(args[0]);
        this._referrals = [...this._referrals, referral];
        this._notifySubscribers();

        toast.success('referralCompleted'.tr());
    }
    

    private async _connectionStateChangedEventHandler(state: SignalRConnected) {
        if (state === SignalRConnected.Connected) {
            if (this._reFetching) {
                return;
            }
            let fetched = false;
            this._reFetching = true;
            while (!fetched) {
                fetched = await this._initValues();
                if (!fetched) {
                    await new Promise((resolve) => setTimeout(resolve, 2000));
                } else {
                    this._notifySubscribers();
                }
            }
            this._reFetching = false;
        }
    }

    async load(): Promise<Array<ComponentError>> {
        await this.setDependencyLocked([AuthenticationComponent]);
        const initiated = await this._initValues();
        if (!initiated) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'questComponentFailedToLoad'
                ),
            ]; // TODO: tr()
        }
        await this.setDependencyLocked([SignalRComponent]);
        const signalRComponent = await ContainerHelper.getSignalRComponent();

        signalRComponent.registerMethodHandler(
            'TaskCompleted',
            this._taskCompletedMethodHandler.bind(this)
        );

        signalRComponent.registerMethodHandler(
            'ReferralCompleted',
            this._referralCompletedMethodHandler.bind(this)
        );

        signalRComponent.addConnectionStateChangedEventHandler(
            this._connectionStateChangedEventHandler.bind(this)
        );

        return [];
    }
    
    async onUnload(): Promise<void> {}

    async onPause(): Promise<void> {}

    async onResume(): Promise<void> {}

    update(sinceLastUpdate: TimeDuration): void {}

    async startTask(taskId: string): Promise<boolean> {
        this._tasks = this._tasks.map((task) => {
            if (task.taskId === taskId) {
                return new Task({
                    ...task,                   
                    status: TaskStatus.Started,                                         
                });
            }
            return task;
        });
        this._notifySubscribers();
        return true;
    }

    async verifyTask(taskId: string): Promise<boolean> {
        try {
            const completion = await QuestApi.verifyTask(taskId);
            this._tasks = this._tasks.map((task) => {
                if (task.taskId === completion.taskId) {
                    return new Task({
                        ...task,
                        completedAt: completion.completedAt,
                        pointsEarned: completion.pointsEarned,
                        tokensEarned: completion.tokensEarned,
                        status: completion.status,
                        verified: completion.status === TaskStatus.Completed,
                                             
                    });
                }
                return task;
            });
            this._notifySubscribers();
            toast.success(`You earned ${completion.pointsEarned} stones`);
            return true;
        } catch (error: any) {
            var errorData = error as StandardHttpResponse;
            if(errorData && errorData.hasErrorMessage){
                toast.error(errorData.errorMessage);
            }
            return false;
        }
    }
}

export default QuestComponent;