import React from 'react';
import ContainerHelper from '../../component_system/component_container/utilities/container_helper';

import ValueContainer from '../../utils/value_container';

import styles from './profile_page.module.css';
import Images from '../../component_system/components/preload/images';
import useFetchComponent from '../../component_system/component_container/utilities/use_fetch_hook';
import LevelProgressBar from '../components/level_progress_bar';
import useObservable from '../../utils/observable/use_observable_hook';

const ProfilePage = () => {
    const currentLevel = useObservable(ValueContainer.currentLevelObservable);
    const currentLevelExperience = useObservable(
        ValueContainer.currentLevelExperienceObservable
    );
    const experienceUntilNextLevel = useObservable(
        ValueContainer.experienceUntilNextLevelObservable
    );
    const characterImageBase64 = useObservable(
        ValueContainer.characterImageBase64Observable
    );
    const totalSteps = useObservable(ValueContainer.totalStepsObservable);
    const totalPurchasedOffers = useObservable(
        ValueContainer.totalPurchasedOffersObservable
    );
    const username = useObservable(ValueContainer.usernameObservable);
    const totalFriendsCount = useObservable(
        ValueContainer.totalFriendsCountObservable
    );

    const navigationComponent = useFetchComponent(
        ContainerHelper.getNavigationComponent
    );

    const experienceGained =
        (currentLevelExperience ?? 1) - (experienceUntilNextLevel ?? 1);

    return (
        <div
            className={styles.profile_page}
            style={{
                backgroundImage: `url(${Images.Backgrounds.Background})`,
            }}
        >
            <div
                className={styles.back_button}
                onClick={() => navigationComponent?.goBack()}
            >
                <img src={Images.Icons.ArrowBack} alt="Back" />
            </div>

            {/* Scrollable Content Wrapper */}
            <div className={styles.scrollable_content}>
                <div className={styles.profile_content}>
                    <p className={styles.username}>{username ?? 'Username'}</p>

                    {characterImageBase64 && (
                        <img
                            src={`data:image/png;base64,${characterImageBase64}`}
                            alt="User avatar"
                            className={styles.avatar}
                        />
                    )}

                    <p className={styles.user_level}>
                        {'level'.tr() + ` ${currentLevel ?? 1}`}
                    </p>

                    <LevelProgressBar
                        width={300}
                        height={20}
                        borderRadius={10}
                        backgroundColor={'var(--secondary-color)'}
                    />

                    <p className={styles.experience_text}>
                        {`${experienceGained.toFixed(0)} / ${(currentLevelExperience ?? 1).toFixed(0)} XP`}
                    </p>
                </div>

                <div className={styles.stats_section}>
                    <h2 className={styles.stats_title}>{'statistics'.tr()}</h2>
                    <div className={styles.stats_grid}>
                        <div className={styles.stat_item}>
                            <div className={styles.stat_label_container}>
                                <p className={styles.stat_label}>
                                    {'steps'.tr()}
                                </p>
                                <img
                                    src={Images.Icons.DiggersGoCoin}
                                    alt={'steps'.tr()}
                                    className={styles.stat_icon}
                                />
                            </div>
                            <p className={styles.stat_value}>
                                {totalSteps ?? 0}
                            </p>
                        </div>
                        <div className={styles.stat_item}>
                            <div className={styles.stat_label_container}>
                                <p className={styles.stat_label}>
                                    {'collected'.tr()}
                                </p>
                                <img
                                    src={Images.NavigationTab.Present}
                                    alt={'collected'.tr()}
                                    className={styles.stat_icon}
                                />
                            </div>
                            <p className={styles.stat_value}>
                                {totalPurchasedOffers ?? 0}
                            </p>
                        </div>
                        <div className={styles.stat_item}>
                            <div className={styles.stat_label_container}>
                                <p className={styles.stat_label}>
                                    {'friends'.tr()}
                                </p>
                                <img
                                    src={Images.NavigationTab.Heart}
                                    alt={'friends'.tr()}
                                    className={styles.stat_icon}
                                />
                            </div>
                            <p className={styles.stat_value}>
                                {totalFriendsCount ?? 0}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfilePage;
