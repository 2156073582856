import { constants } from '../../utils/constants';
import StandardHttpAPI from '../standard_http_api';
import ValueContainer from '../../utils/value_container';
import CompactJsonOffer from '../offer_api/compact_json_offer';
import AcquiredOffer from '../offer_api/acquired_offer';

const API_URL = constants.API_URL;

class BubbleApi {
    static async getOffersForBubble(
        bubbleId: number | string
    ): Promise<CompactJsonOffer[]> {
        const response = await StandardHttpAPI.get({
            uri: `${API_URL}bubble/${bubbleId}/offers`,
            bearer: ValueContainer.token,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return response.response.map((json: any) =>
            CompactJsonOffer.fromJsonLowerCamelCase(json)
        );
    }

    static async purchaseOffer(offerId: string): Promise<AcquiredOffer> {
        const response = await StandardHttpAPI.post({
            uri: `${API_URL}offer/purchase/${offerId}`,
            bearer: ValueContainer.token,
            body: undefined,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return AcquiredOffer.fromJsonLowerCamelCase(response.response);
    }
}

export default BubbleApi;
