import React from 'react';

class ElementData {
    id: string;
    children: React.ReactNode;
    ref: React.RefObject<{
        setPosition: (x: number, y: number) => void;
        triggerFadeOut: () => void;
    }>;
    created: number;
    lifetime: number | undefined;
    gameObject: string;
    initialPosition: { x: number; y: number };
    bottomOffset: number;

    constructor(
        id: string,
        children: React.ReactNode,
        ref: React.RefObject<{
            setPosition: (x: number, y: number) => void;
            triggerFadeOut: () => void;
        }>,
        created: number,
        lifetime: number | undefined,
        gameObject: string,
        initialPosition: { x: number; y: number } = { x: 0, y: 0 },
        bottomOffset: number = 50
    ) {
        this.id = id;
        this.children = children;
        this.ref = ref;
        this.created = created;
        this.lifetime = lifetime;
        this.gameObject = gameObject;
        this.initialPosition = initialPosition;
        this.bottomOffset = bottomOffset;
    }
}

export default ElementData;
