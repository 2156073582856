import Component from '../../component_container/models/component';
import ComponentError from '../../component_container/models/component_error';
import { TimeDuration } from 'typed-duration';
import UnityComponent from '../unity/unity_component';
import SignalRComponent from '../signalr/signalr_component';
import MapBubble from '../../../apis/models/map_bubble';
import MapProfile from '../../../apis/models/map_profile';
import MapEvent from '../../../apis/models/map_event';
import PopulationApi from '../../../apis/population_api/population_api';
import ComponentErrorType from '../../component_container/enums/component_error_type';
import Unity_component from '../unity/unity_component';
import AuthenticationComponent from '../authentication/authentication_component';
import Color from '../../../utils/color';

type EventListener = (event: string, args: any[]) => void;

class PopulationComponent extends Component {
    private _onBubbleTapListeners: Map<string, EventListener> = new Map();
    private _onProfileTapListeners: Map<string, EventListener> = new Map();
    private _onEventTapListeners: Map<string, EventListener> = new Map();

    private get _unityComponent(): Promise<UnityComponent> {
        return this.getComponent(UnityComponent).then(
            (c) => c as UnityComponent
        );
    }

    private get _signalRComponent(): Promise<SignalRComponent> {
        return this.getComponent(SignalRComponent).then(
            (c) => c as SignalRComponent
        );
    }

    private _bubbles: MapBubble[] = [];
    private _profiles: MapProfile[] = [];
    private _events: MapEvent[] = [];

    // TODO: OverlayComponent

    async load(): Promise<Array<ComponentError>> {
        await this.setDependencyLocked([
            UnityComponent,
            AuthenticationComponent,
            SignalRComponent,
        ]);

        const success = await this._updatePopulation(true);

        if (!success) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'populationComponentFailedToLoad' // TODO: tr()
                ),
            ];
        }

        this._signalRComponent.then((signalRComponent) => {
            signalRComponent.registerMethodHandler(
                'UpdateSurroundings',
                (...args) => {
                    const obj = args![0] as string;
                    const json = JSON.parse(obj);

                    const addedBubbles = (
                        json['AddedBubbles'] as Array<any>
                    ).map((e: any) => MapBubble.fromJson(e));
                    const removedBubbles = (
                        json['RemovedBubbles'] as Array<any>
                    ).map((e: any) => MapBubble.fromJson(e));
                    const addedProfiles = (
                        json['AddedProfiles'] as Array<any>
                    ).map((e: any) => MapProfile.fromJson(e));
                    const removedProfiles = (
                        json['RemovedProfiles'] as Array<any>
                    ).map((e: any) => MapProfile.fromJson(e));
                    const addedEvents = (json['AddedEvents'] as Array<any>).map(
                        (e: any) => MapEvent.fromJson(e)
                    );
                    const removedEvents = (
                        json['RemovedEvents'] as Array<any>
                    ).map((e: any) => MapEvent.fromJson(e));

                    for (const bubble of removedBubbles) {
                        if (
                            this._bubbles.some(
                                (element) => element.id === bubble.id
                            )
                        ) {
                            this._removeBubble(bubble);
                        }
                    }

                    for (const bubble of addedBubbles) {
                        if (
                            !this._bubbles.some(
                                (element) => element.id === bubble.id
                            )
                        ) {
                            this._addBubble(bubble);
                        }
                    }

                    for (const profile of removedProfiles) {
                        if (
                            this._profiles.some((element) =>
                                element.isSame(profile)
                            )
                        ) {
                            this._removeProfile(profile);
                        }
                    }

                    for (const profile of addedProfiles) {
                        if (
                            !this._profiles.some((element) =>
                                element.isSame(profile)
                            )
                        ) {
                            this._addProfile(profile);
                        }
                    }

                    for (const event of removedEvents) {
                        if (
                            this._events.some(
                                (element) => element.id === event.id
                            )
                        ) {
                            this._removeEvent(event);
                        }
                    }

                    for (const event of addedEvents) {
                        if (
                            !this._events.some(
                                (element) => element.id === event.id
                            )
                        ) {
                            this._addEvent(event);
                        }
                    }
                }
            );
        });

        return [];
    }

    get name(): string {
        return 'Population Component';
    }

    async onPause(): Promise<void> {}

    async onResume(): Promise<void> {}

    async onUnload(): Promise<void> {}

    get type(): Function {
        return PopulationComponent;
    }

    update(sinceLastUpdate: TimeDuration): void {}

    private _removeBubble(bubble: MapBubble): void {
        console.log('Removing bubble', bubble);
        this._unityComponent.then((unityComponent) => {
            unityComponent.destroyGameObject(bubble.id.toString());
        });

        const eventListener = this._onBubbleTapListeners.get(
            bubble.id.toString()
        );
        if (eventListener) {
            this._unityComponent.then((unityComponent) => {
                unityComponent.removeEventListener(eventListener);
            });
        }

        this._bubbles = this._bubbles.filter(
            (element) => element.id !== bubble.id
        );

        this._onBubbleTapListeners.delete(bubble.id.toString());
    }

    private _addBubble(bubble: MapBubble): void {
        console.log('Adding bubble', bubble);
        const profileColor = bubble.metadata!['ProfileColor']
            ? Color.fromJson(bubble.metadata!['ProfileColor'])
            : undefined;
        const eventColor = bubble.metadata!['EventColor']
            ? Color.fromJson(bubble.metadata!['EventColor'])
            : undefined;
        const rarityColor = bubble.metadata!['RarityColor']
            ? Color.fromJson(bubble.metadata!['RarityColor'])
            : undefined;

        const bubbleType = bubble.metadata!['Type'] as string | undefined;
        const rarity = bubble.metadata!['Rarity'] as string | undefined;

        if (bubbleType === 'Stone') {
            this._unityComponent.then(async (unityComponent) => {
                await unityComponent.createStoneBubble({
                    name: bubble.id.toString(),
                    latitude: bubble.latitude,
                    longitude: bubble.longitude,
                    isEvent: eventColor !== undefined,
                    color: eventColor ?? profileColor ?? rarityColor!,
                    ringColor: rarityColor!,
                    rarity: rarity!,
                });
            });
        }

        const eventListener = (event: string, args: any[]) => {
            if (event === 'gameObject:touch:event') {
                if (args[0] === bubble.id.toString()) {
                    // TODO: _onTapBubble(bubble)();
                }
            }
        };

        this._onBubbleTapListeners.set(bubble.id.toString(), eventListener);
        this._unityComponent.then((unityComponent) => {
            unityComponent.addEventListener(eventListener);
        });

        this._bubbles.push(bubble);
    }

    private _removeProfile(profile: MapProfile): void {}

    private _addProfile(profile: MapProfile): void {}

    private _removeEvent(event: MapEvent): void {}

    private _addEvent(event: MapEvent): void {}

    async _updatePopulation(initial: boolean = false): Promise<boolean> {
        const response = await PopulationApi.getPopulation();

        if (!response) {
            return false;
        }

        const bubblesToRemove = this._bubbles.filter(
            (element) =>
                !response.bubbles.some((element2) => element2.id === element.id)
        );

        const bubblesToAdd = response.bubbles.filter(
            (element) =>
                !this._bubbles.some((element2) => element2.id === element.id)
        );

        if (!initial) {
            for (const bubble of bubblesToRemove) {
                this._removeBubble(bubble);
            }
        }

        for (const bubble of bubblesToAdd) {
            this._addBubble(bubble);
        }

        const profilesToRemove = this._profiles.filter(
            (element) =>
                !response.mapProfiles.some((element2) =>
                    element.isSame(element2)
                )
        );

        const profilesToAdd = response.mapProfiles.filter(
            (element) =>
                !this._profiles.some((element2) => element.isSame(element2))
        );

        if (!initial) {
            for (const profile of profilesToRemove) {
                this._removeProfile(profile);
            }
        }

        for (const profile of profilesToAdd) {
            this._addProfile(profile);
        }

        const eventsToRemove = this._events.filter(
            (element) =>
                !response.mapEvents.some(
                    (element2) => element2.id === element.id
                )
        );

        const eventsToAdd = response.mapEvents.filter(
            (element) =>
                !this._events.some((element2) => element2.id === element.id)
        );

        if (!initial) {
            for (const event of eventsToRemove) {
                this._removeEvent(event);
            }
        }

        for (const event of eventsToAdd) {
            this._addEvent(event);
        }

        return true;
    }

    getBubble(id: number): MapBubble | undefined {
        return this._bubbles.find((element) => element.id === id);
    }
}

export default PopulationComponent;
