import { TimeDuration } from 'typed-duration';
import Component from '../../component_container/models/component';
import ComponentError from '../../component_container/models/component_error';
import Friend from '../../../apis/models/friend/friend';
import FriendApi from '../../../apis/friend_api/friend_api';
import ComponentErrorType from '../../component_container/enums/component_error_type';
import SignalRComponent from '../signalr/signalr_component';
import ContainerHelper from '../../component_container/utilities/container_helper';
import SignalRConnected from '../signalr/signalr_connected';
import AuthenticationComponent from '../authentication/authentication_component';
import ValueContainer from '../../../utils/value_container';
import { toast } from 'react-toastify';
import { search_params_keys } from '../../../utils/constants';

type FriendComponentSubscriber = () => void;

class FriendComponent extends Component {
    private _friends: Friend[] = [];
    private _incomingFriendRequests: Friend[] = [];
    private _outgoingFriendRequests: Friend[] = [];

    private _friendConnectionStatus: Map<string, boolean> = new Map<
        string,
        boolean
    >();

    private _subscribers: FriendComponentSubscriber[] = [];

    private _reFetching: boolean = false;

    get friends(): Friend[] {
        return this._friends;
    }

    get incomingFriendRequests(): Friend[] {
        return this._incomingFriendRequests;
    }

    get outgoingFriendRequests(): Friend[] {
        return this._outgoingFriendRequests;
    }

    get onlineFriends(): Friend[] {
        return this._friends.filter(
            (friend) =>
                this._friendConnectionStatus.get(friend.phoneNumber!) ?? false
        );
    }

    get offlineFriends(): Friend[] {
        return this._friends.filter(
            (friend) =>
                !(
                    this._friendConnectionStatus.get(friend.phoneNumber!) ??
                    false
                )
        );
    }

    addSubscriber(subscriber: FriendComponentSubscriber): void {
        this._subscribers.push(subscriber);
    }

    removeSubscriber(subscriber: FriendComponentSubscriber): void {
        this._subscribers = this._subscribers.filter(
            (existingSubscriber) => existingSubscriber !== subscriber
        );
    }

    private _notifySubscribers(): void {
        this._notifyObservers({});
        ValueContainer.totalFriendsCount = this._friends.length;
        this._subscribers.forEach((subscriber) => subscriber());
    }

    private async _initValues(): Promise<boolean> {
        try {
            this._friends = await FriendApi.getFriends();
            this._incomingFriendRequests =
                await FriendApi.getIncomingFriendRequests();
            this._outgoingFriendRequests =
                await FriendApi.getOutgoingFriendRequests();
            this._friendConnectionStatus =
                await FriendApi.getFriendsConnectionStatus();

            this._notifySubscribers();
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    get type(): Function {
        return FriendComponent;
    }

    get name(): string {
        return 'Friend Component';
    }

    private _friendRequestAcceptedMethodHandler(...args: any[]) {
        const friend = Friend.fromJsonUpperCase(JSON.parse(args[0]));
        this._friends.push(friend);

        this._outgoingFriendRequests = this._outgoingFriendRequests.filter(
            (existingFriend) =>
                existingFriend.phoneNumber !== friend.phoneNumber
        );

        this._notifySubscribers();
    }

    private _friendRequestReceivedMethodHandler(...args: any[]) {
        const friend = Friend.fromJsonUpperCase(JSON.parse(args[0]));
        this._incomingFriendRequests.push(friend);
        this._notifySubscribers();
    }

    private _friendRemovedMethodHandler(...args: any[]) {
        const friend = Friend.fromJsonUpperCase(JSON.parse(args[0]));
        this._friends = this._friends.filter(
            (existingFriend) =>
                existingFriend.phoneNumber !== friend.phoneNumber
        );
        this._notifySubscribers();
    }

    private _friendConnectionStatusMethodHandler(...args: any[]) {
        const friend = Friend.fromJsonUpperCase(JSON.parse(args[0]));
        const connectionStatus = args[1] as boolean;
        this._friendConnectionStatus.set(friend.phoneNumber!, connectionStatus);
        this._notifySubscribers();
    }

    private async _connectionStateChangedEventHandler(state: SignalRConnected) {
        if (state === SignalRConnected.Connected) {
            if (this._reFetching) {
                return;
            }
            let fetched = false;
            this._reFetching = true;
            while (!fetched) {
                fetched = await this._initValues();
                if (!fetched) {
                    await new Promise((resolve) => setTimeout(resolve, 2000));
                } else {
                    this._notifySubscribers();
                }
            }
            this._reFetching = false;
        }
    }

    async load(): Promise<ComponentError[]> {
        await this.setDependencyLocked([AuthenticationComponent]);
        const initiated = await this._initValues();
        if (!initiated) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'friendComponentFailedToLoad'
                ),
            ]; // TODO: tr()
        }
        await this.setDependencyLocked([SignalRComponent]);
        const signalRComponent = await ContainerHelper.getSignalRComponent();

        signalRComponent.registerMethodHandler(
            'FriendRequestAccepted',
            this._friendRequestAcceptedMethodHandler.bind(this)
        );

        signalRComponent.registerMethodHandler(
            'FriendRequestReceived',
            this._friendRequestReceivedMethodHandler.bind(this)
        );

        signalRComponent.registerMethodHandler(
            'FriendRemoved',
            this._friendRemovedMethodHandler.bind(this)
        );

        signalRComponent.registerMethodHandler(
            'FriendConnection',
            this._friendConnectionStatusMethodHandler.bind(this)
        );

        signalRComponent.addConnectionStateChangedEventHandler(
            this._connectionStateChangedEventHandler.bind(this)
        );

        await this._tryAddFriendFromSearchParameter();

        return [];
    }

    async _tryAddFriendFromSearchParameter(): Promise<void> {
        const navComponent = ContainerHelper.forceGetNavigationComponent();
        const initialSearchParams =
            await navComponent.initialSearchParamsCompleter.promise;

        const friendTelegramId =
            initialSearchParams.get(
                search_params_keys.SEARCH_PARAMS_KEY_FRIEND_REFERER
            ) || undefined;

        if (!friendTelegramId) {
            return;
        }

        if (this._friends.find((f) => f.telegramId === friendTelegramId)) {
            return;
        }

        if (
            this._outgoingFriendRequests.find(
                (f) => f.telegramId === friendTelegramId
            )
        ) {
            return;
        }

        if (
            this._incomingFriendRequests.find(
                (f) => f.telegramId === friendTelegramId
            )
        ) {
            await toast
                .promise(
                    new Promise<void>(async (resolve, reject) => {
                        const accepted =
                            await this.acceptFriendRequest(friendTelegramId);

                        if (accepted) {
                            resolve();
                        } else {
                            reject();
                        }
                    }),
                    {
                        pending: 'acceptingFriendRequest'.tr(),
                        success: 'friendRequestAccepted'.tr(),
                        error: 'friendRequestAcceptFailed'.tr(),
                    }
                )
                .catch(() => {});
            return;
        }

        await toast
            .promise(
                new Promise<void>(async (resolve, reject) => {
                    const added =
                        await this.sendFriendRequest(friendTelegramId);

                    if (added) {
                        resolve();
                    } else {
                        reject();
                    }
                }),
                {
                    pending: 'addingFriend'.tr(),
                    success: 'friendRequestSent'.tr(),
                    error: 'friendRequestFailed'.tr(),
                }
            )
            .catch(() => {});
    }

    async onUnload(): Promise<void> {}

    async onPause(): Promise<void> {}

    async onResume(): Promise<void> {}

    update(sinceLastUpdate: TimeDuration): void {}

    isOnline(phoneNumber: string): boolean {
        return this._friendConnectionStatus.get(phoneNumber) ?? false;
    }

    async sendFriendRequest(phoneNumber: string): Promise<boolean> {
        try {
            const friend = await FriendApi.sendFriendRequest(phoneNumber);
            this._outgoingFriendRequests.push(friend);
            this._notifySubscribers();
            return true;
        } catch (error) {
            return false;
        }
    }

    async acceptFriendRequest(phoneNumber: string): Promise<boolean> {
        try {
            const friend = await FriendApi.acceptFriendRequest(phoneNumber);
            this._friends.push(friend);
            this._incomingFriendRequests = this._incomingFriendRequests.filter(
                (existingFriend) => existingFriend.phoneNumber !== phoneNumber
            );
            this._notifySubscribers();
            return true;
        } catch (error) {
            return false;
        }
    }

    async declineFriendRequest(phoneNumber: string): Promise<boolean> {
        throw new Error('Method not implemented.'); // TODO: Implement server-side
        // try {
        //     await FriendApi.declineFriendRequest(phoneNumber);
        //     this._incomingFriendRequests = this._incomingFriendRequests.filter(
        //         (existingFriend) => existingFriend.phoneNumber !== phoneNumber
        //     );
        //     this._notifySubscribers();
        //     return true;
        // } catch (error) {
        //     return false;
        // }
    }

    async removeFriend(phoneNumber: string): Promise<boolean> {
        try {
            await FriendApi.removeFriend(phoneNumber);
            this._friends = this._friends.filter(
                (friend) => friend.phoneNumber !== phoneNumber
            );
            this._notifySubscribers();
            return true;
        } catch (error) {
            return false;
        }
    }
}

export default FriendComponent;
