import CompanyProfile from './company_profile';
import OfferType from './offer_type';

class AcquiredOffer {
    nonUniqueId: string;
    uniqueId: string;
    offerType: OfferType;
    offerTitle: string;
    description: string;
    acquiredAtDateUtc: Date;
    expiryDateUTC: Date;
    companyProfile: CompanyProfile;
    coinWorth: number;
    coverUrl: string;
    isSeen: boolean;
    isUsed: boolean;
    categories: string[];
    offerRarity?: string;
    marketListingId?: string;
    scannableUrl?: string;
    redeemableCode?: string;
    cryptoCurrency?: string;
    cryptoCurrencyAmount?: number;


    constructor(
        nonUniqueId: string,
        uniqueId: string,
        offerType: OfferType,
        offerTitle: string,
        description: string,
        acquiredAtDateUtc: Date,
        expiryDateUTC: Date,
        companyProfile: CompanyProfile,
        coinWorth: number,
        coverUrl: string,
        isSeen: boolean,
        isUsed: boolean,
        categories: string[],
        offerRarity?: string,
        marketListingId?: string,
        scannableUrl?: string,
        redeemableCode?: string,
        cryptoCurrency?: string,
        cryptoCurrencyAmount?: number
    ) {
        this.nonUniqueId = nonUniqueId;
        this.uniqueId = uniqueId;
        this.offerType = offerType;
        this.offerTitle = offerTitle;
        this.description = description;
        this.acquiredAtDateUtc = acquiredAtDateUtc;
        this.expiryDateUTC = expiryDateUTC;
        this.companyProfile = companyProfile;
        this.coinWorth = coinWorth;
        this.coverUrl = coverUrl;
        this.isSeen = isSeen;
        this.isUsed = isUsed;
        this.categories = categories;
        this.offerRarity = offerRarity;
        this.marketListingId = marketListingId;
        this.scannableUrl = scannableUrl;
        this.redeemableCode = redeemableCode;
        this.cryptoCurrency = cryptoCurrency;
        this.cryptoCurrencyAmount = cryptoCurrencyAmount;
    }

    static fromJson(json: { [key: string]: any }): AcquiredOffer {
        return new AcquiredOffer(
            json['OfferId'],
            json['AcquiredOfferId'],
            OfferType[
                json['OfferType'].toUpperCase() as keyof typeof OfferType
            ],
            json['OfferTitle'],
            json['OfferDescription'],
            new Date(json['AcquiredAtDateUtc']),
            new Date(json['ExpiryDateUtc']),
            CompanyProfile.fromJson(json['CompanyProfile']),
            json['OfferCost'],
            json['CoverUrl'],
            json['IsSeen'],
            json['IsUsed'],
            json['Categories'] ? [...json['Categories']] : [],
            json['OfferRarity'],
            json['MarketListingId'],
            json['ScannableUrl'],
            json['RedeemableCode'],
            json['CryptoCurrency'],
            json['CryptoCurrencyAmount']
        );
    }

    static fromJsonLowerCamelCase(json: { [key: string]: any }): AcquiredOffer {
        return new AcquiredOffer(
            json['offerId'],
            json['acquiredOfferId'],
            OfferType[
                json['offerType'].toUpperCase() as keyof typeof OfferType
            ],
            json['offerTitle'],
            json['offerDescription'],
            new Date(json['acquiredAtDateUtc']),
            new Date(json['expiryDateUtc']),
            CompanyProfile.fromJsonLowerCamelCase(json['companyProfile']),
            json['offerCost'],
            json['coverUrl'],
            json['isSeen'],
            json['isUsed'],
            json['categories'] ? [...json['categories']] : [],
            json['offerRarity'],
            json['marketListingId'],
            json['scannableUrl'],
            json['redeemableCode'],
            json['cryptoCurrency'],
            json['cryptoCurrencyAmount']
        );
    }

    isExpired(): boolean {
        return this.expiryDateUTC < new Date();
    }

    isRedeemable(): boolean {
        return this.offerType === OfferType.Redeemable;
    }

    isScannable(): boolean {
        return this.offerType === OfferType.Scannable;
    }

    isDismissable(): boolean {
        return this.offerType === OfferType.Dismissible;
    }
}

export default AcquiredOffer;
