import React, { useEffect, useState, useCallback } from 'react';
import ComponentError from '../../component_system/component_container/models/component_error';
import LoadStatus from '../../component_system/component_container/models/load_status';
import ComponentContainer from '../../component_system/component_container/component_container';
import ProgressInfo from '../../component_system/component_container/models/progress_info';
import styles from './home_page.module.css';
import TelegramComponent from '../../component_system/components/telegram/telegram_component';
import UnityPage from './unity_page';
import { component_error_codes } from '../../utils/constants';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ContainerHelper from '../../component_system/component_container/utilities/container_helper';
import CustomBottomNavigationBar from '../components/custom_bottom_navigation_bar';
import MapOverlay from '../overlays/map_overlay';
import Images from '../../component_system/components/preload/images';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HomePage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [hasContent, setHasContent] = useState(false);

    useEffect(() => {
        ContainerHelper.getNavigationComponent().then((component) => {
            component.search = location.search;
        });

        ComponentContainer.instance!.makeSureLoaded.then(() => {
            ContainerHelper.getNavigationComponent().then((component) => {
                component.locationPathname = location.pathname;
            });
        });

        setHasContent(location.pathname !== '/');
    }, [location]);

    useEffect(() => {
        const component = ContainerHelper.forceGetNavigationComponent();
        component.setNavigateFunction(navigate);
    }, [navigate]);

    const [loadStatus, setLoadStatus] = useState('loading'.tr());
    const [errors, setErrors] = useState<Map<string, ComponentError[]>>(
        new Map()
    );
    const [showMap, setShowMap] = useState(false);
    const [retryButtonEnabled, setRetryButtonEnabled] = useState(false);
    const [componentContainerLoaded, setComponentContainerLoaded] =
        useState(false);
    const [loadStatuses, setLoadStatuses] = useState<Map<string, LoadStatus>>(
        new Map()
    );
    const [showStatusModal, setShowStatusModal] = useState(false);

    const [username, setUsername] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [showTelegramModal, setShowTelegramModal] = useState(false);

    useEffect(() => {
        const container = ComponentContainer.instance;

        container!.onProgress = (progress: ProgressInfo) =>
            setLoadStatus(progress.componentLoadStatus);

        container!
            .tryInitialize()
            .then(() => setShowMap(true))
            .catch((error) => {
                setErrors(error as Map<string, ComponentError[]>);
                const errors = error as Map<string, ComponentError[]>;
                // Make sure that error contains more than just the 'Container' key with the component_error_codes.CONTAINER_ALREADY_INITIALIZED error
                if (errors.size > 1 || !errors.has('Container')) {
                    setRetryButtonEnabled(true);
                }
            });

        container!.makeSureLoaded.then(() => {
            setComponentContainerLoaded(true);
            setShowMap(true);
        });

        ContainerHelper.getNavigationComponent().then((component) => {
            // component.navigateToIndex(0); TODO: Uncomment this line
        });

        return () => {
            container!.onProgress = () => {};
        };
    }, []);

    useEffect(() => {
        if (
            errors.has('Telegram Component') &&
            errors
                .get('Telegram Component')!
                .some(
                    (e) =>
                        e.errorCode ===
                        component_error_codes.TELEGRAM_REGISTRATION_INCOMPLETE
                )
        ) {
            setShowTelegramModal(true);
        }
    }, [errors]);

    const handleRetry = useCallback(() => {
        setRetryButtonEnabled(false);
        setErrors(new Map());

        ComponentContainer.instance!.tryRetry()
            .then(() => setShowMap(true))
            .catch((error) => {
                setErrors(error as Map<string, ComponentError[]>);
                setRetryButtonEnabled(true);
            });
    }, []);

    const handleStatusModalOpen = useCallback(() => {
        setLoadStatuses(ComponentContainer.instance!.componentLoadedStatus());
        setShowStatusModal(true);
    }, []);

    const handleStatusModalClose = () => setShowStatusModal(false);

    const handleTelegramSubmit = async () => {
        const component = ComponentContainer.instance!.forceGet(
            TelegramComponent
        ) as TelegramComponent;

        const usernameValidationResult =
            await component.validateUsername(username);

        if (!usernameValidationResult.isValid) {
            setUsernameError(
                usernameValidationResult.message ?? 'Unknown error'
            ); // TODO: tr()
            return;
        }

        component.usernameCreationParameter = username;
        setShowTelegramModal(false);
    };

    return (
        <div>
            <ToastContainer
                theme={'colored'}
                bodyStyle={{
                    fontFamily: 'Luckiest Guy, cursive',
                }}
                // draggableDirection={'y'}
                stacked={true}
                pauseOnFocusLoss={false}
                pauseOnHover={false}
                autoClose={3000}
            />
            <UnityPage />
            <MapOverlay />
            <div
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    zIndex: 2,
                    pointerEvents: hasContent ? 'auto' : 'none', // Only capture events if there's content
                }}
            >
                <Outlet />
            </div>
            <CustomBottomNavigationBar navigate={navigate} />
            {!showMap && (
                <div
                    className={styles.loadingOverlay}
                    style={{
                        backgroundImage: `url(${Images.Backgrounds.Background})`,
                    }}
                >
                    <img
                        className={styles.loadingIcon}
                        src={Images.Logos.logoNoBg}
                        alt="Loading"
                    />
                    <p className={styles.loadStatusText}>{loadStatus}</p>

                    {errors.size > 0 && (
                        <div className={styles.errorList}>
                            {Array.from(errors.entries())
                                .filter((e) => e[1].length > 0)
                                .map(([key, errorList]) => (
                                    <div key={key} className={styles.errorItem}>
                                        <strong>{key}</strong>:{' '}
                                        {errorList
                                            .map((e) => e.errorMessage)
                                            .join(', ')}
                                    </div>
                                ))}
                        </div>
                    )}

                    {showTelegramModal && (
                        <div className={styles.telegramModalOverlay}>
                            <div className={styles.telegramModal}>
                                <div className={styles.telegramModalHeader}>
                                    <h2>Telegram Registration</h2>
                                    <button
                                        className={styles.closeButton}
                                        onClick={() =>
                                            setShowTelegramModal(false)
                                        }
                                    >
                                        ✖
                                    </button>
                                </div>
                                <div className={styles.telegramModalBody}>
                                    <p>
                                        Please enter your username to continue
                                        registration
                                    </p>
                                    <input
                                        className={styles.usernameInput}
                                        type="text"
                                        placeholder="Username"
                                        value={username}
                                        onChange={(e) =>
                                            setUsername(e.target.value)
                                        }
                                    />
                                    {usernameError && (
                                        <p className={styles.usernameError}>
                                            {usernameError}
                                        </p>
                                    )}
                                </div>
                                <div className={styles.telegramModalFooter}>
                                    <button
                                        className={styles.submitButton}
                                        onClick={handleTelegramSubmit}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {showStatusModal && (
                        <div className={styles.modalOverlay}>
                            <div className={styles.modal}>
                                <div className={styles.modalHeader}>
                                    <h2>Load Status</h2>
                                    <button
                                        className={styles.closeButton}
                                        onClick={handleStatusModalClose}
                                    >
                                        ✖
                                    </button>
                                </div>
                                <div className={styles.modalBody}>
                                    {Array.from(loadStatuses.entries()).map(
                                        ([name, status]) => (
                                            <div
                                                key={name}
                                                className={styles.loadStatus}
                                            >
                                                <span>{name}</span>
                                                <span
                                                    className={
                                                        status.loaded
                                                            ? styles.statusIconSuccess
                                                            : styles.statusIconFailure
                                                    }
                                                >
                                                    {status.loaded ? '✓' : '⨯'}
                                                </span>
                                                {status.dependencyLocked && (
                                                    <span
                                                        className={
                                                            styles.lockIcon
                                                        }
                                                    >
                                                        🔒
                                                    </span>
                                                )}
                                            </div>
                                        )
                                    )}
                                </div>
                                <div className={styles.modalFooter}>
                                    <button
                                        onClick={() => {
                                            handleStatusModalClose();
                                            setShowMap(true);
                                        }}
                                        className={styles.showMapButton}
                                    >
                                        Show Map
                                    </button>
                                    <button
                                        onClick={handleStatusModalClose}
                                        className={styles.closeModalButton}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {retryButtonEnabled && (
                        <button
                            onClick={handleRetry}
                            className={styles.retryButton}
                        >
                            <span className={styles.retryIcon}>↻</span> Retry
                        </button>
                    )}

                    <button
                        onClick={handleStatusModalOpen}
                        className={styles.loadStatusButton}
                    >
                        <span className={styles.helpIcon}>?</span> Load Status
                    </button>
                </div>
            )}
        </div>
    );
};

export default HomePage;
