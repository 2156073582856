import React from 'react';
import Images from '../../component_system/components/preload/images';
import styles from './friends_page.module.css';
import useFetchComponent from '../../component_system/component_container/utilities/use_fetch_hook';
import ContainerHelper from '../../component_system/component_container/utilities/container_helper';
import Friend from '../../apis/models/friend/friend';
import UserAvatar from '../components/user_avatar';
import FriendComponent from '../../component_system/components/friend/friend_component';
import { toast } from 'react-toastify';
import { confirm } from '../components/confirm_dialog';
import ValueContainer from '../../utils/value_container';
import { init, shareURL } from '@telegram-apps/sdk-react';
import { constants } from '../../utils/constants';

const ADD_FRIEND_URL_STRUCTURE = constants.ADD_FRIEND_URL_STRUCTURE;

const enum FriendCardType {
    Friend,
    IncomingRequest,
    OutgoingRequest,
}

type FriendCardProps = {
    friend: Friend;
    online: boolean;
    cardType: FriendCardType;
    friendComponent: FriendComponent | undefined;
};

const FriendCard = ({
    friend,
    online,
    friendComponent,
    cardType,
}: FriendCardProps) => {
    const removeFriend = async () => {
        const confirmation = await confirm({
            options: {
                title: 'removeFriend'.tr(),
                message: 'removeFriendConfirmation'.tr(),
            },
        });

        if (!confirmation) {
            return;
        }

        await toast
            .promise(
                new Promise<void>(async (resolve, reject) => {
                    const removed = await friendComponent?.removeFriend(
                        friend.phoneNumber!
                    );

                    if (removed) {
                        resolve();
                    } else {
                        reject();
                    }
                }),
                {
                    pending: 'removingFriend'.tr(),
                    success: 'friendRemoved'.tr(),
                    error: 'errorRemovingFriend'.tr(),
                }
            )
            .catch(() => {});
    };

    const acceptFriendRequest = async () => {
        await toast
            .promise(
                new Promise<void>(async (resolve, reject) => {
                    const accepted = await friendComponent?.acceptFriendRequest(
                        friend.phoneNumber!
                    );

                    if (accepted) {
                        resolve();
                    } else {
                        reject();
                    }
                }),
                {
                    pending: 'acceptingFriendRequest'.tr(),
                    success: 'friendRequestAccepted'.tr(),
                    error: 'friendRequestAcceptFailed'.tr(),
                }
            )
            .catch(() => {});
    };

    const declineFriendRequest = async () => {
        await toast
            .promise(
                new Promise<void>(async (resolve, reject) => {
                    const declined =
                        await friendComponent?.declineFriendRequest(
                            friend.phoneNumber!
                        );

                    if (declined) {
                        resolve();
                    } else {
                        reject();
                    }
                }),
                {
                    pending: 'decliningFriendRequest'.tr(),
                    success: 'friendRequestDeclined'.tr(),
                    error: 'friendRequestDeclineFailed'.tr(),
                }
            )
            .catch(() => {});
    };

    return (
        <div className={styles.friend_card}>
            <div className={styles.friend_card_username_avatar}>
                <div className={styles.friend_card_avatar_container}>
                    <UserAvatar
                        size={50}
                        showLevel={false}
                        imageUri={friend.avatarUrl}
                    />
                    {cardType === FriendCardType.Friend && (
                        <div
                            className={styles.friend_card_online_status}
                            style={{
                                backgroundColor: online
                                    ? 'var(--avatar-online-color)'
                                    : 'var(--avatar-offline-color)',
                            }}
                        />
                    )}
                </div>
                <div className={styles.friend_card_username}>
                    {friend.username}
                </div>
            </div>
            <div className={styles.friend_actions_container}>
                {cardType === FriendCardType.Friend && (
                    <>
                        <div
                            className={styles.friend_action_button}
                            // onClick={() => giftFriend()} // TODO: implement
                        >
                            <img
                                src={Images.NavigationTab.Present}
                                alt="Gift"
                            />
                        </div>
                        <div
                            className={styles.friend_action_button}
                            onClick={() => removeFriend()}
                        >
                            <img src={Images.Icons.Close} alt="Remove" />
                        </div>
                    </>
                )}
                {cardType === FriendCardType.IncomingRequest && (
                    <>
                        <div
                            className={styles.friend_action_button}
                            onClick={acceptFriendRequest}
                        >
                            <img src={Images.Icons.Checkmark} alt="Accept" />
                        </div>
                        <div
                            className={styles.friend_action_button}
                            onClick={() => {}}
                        >
                            <img src={Images.Icons.Close} alt="Reject" />
                        </div>
                    </>
                )}
                {cardType === FriendCardType.OutgoingRequest && (
                    <div className={styles.waiting_for_response}>
                        {`waiting`.tr()}
                    </div>
                )}
            </div>
            {/*<div className={styles.friend}>{`Level ${friend.level}`}</div>*/}
        </div>
    );
};

const FriendsPage = () => {
    init();

    const navigationComponent = useFetchComponent(
        ContainerHelper.getNavigationComponent
    );

    const friendsComponent = useFetchComponent(
        ContainerHelper.getFriendComponent
    );

    const addFriend = async () => {
        const confirmation = await confirm({
            options: {
                title: 'addFriend'.tr(),
                message: 'username'.tr(),
                isInput: true,
            },
        });

        // check if confirmation is a string
        if (typeof confirmation !== 'string') {
            return;
        }

        // make sure not empty
        if (!confirmation) {
            toast.error('nameCannotBeEmpty'.tr());
            return;
        }

        await toast
            .promise(
                new Promise<void>(async (resolve, reject) => {
                    const added =
                        await friendsComponent?.sendFriendRequest(confirmation);

                    if (added) {
                        resolve();
                    } else {
                        reject();
                    }
                }),
                {
                    pending: 'addingFriend'.tr(),
                    success: 'friendRequestSent'.tr(),
                    error: 'friendRequestFailed'.tr(),
                }
            )
            .catch(() => {});
    };

    const onShareFriendLinkClick = async () => {
        const inviteLink = ADD_FRIEND_URL_STRUCTURE.replace(
            '%TID%',
            ValueContainer.telegramId
        );
        if (window.Telegram !== undefined)
            shareURL(inviteLink, 'Hey! Add me as a friend on DIGGERS GO! 🎮');
    };

    return (
        <div
            className={styles.friends_page}
            style={{
                backgroundImage: `url(${Images.Backgrounds.Background})`,
            }}
        >
            <div className={styles.friends_header}>
                <div
                    className={styles.back_button}
                    onClick={() => navigationComponent?.goBack()}
                >
                    <img src={Images.Icons.ArrowBack} alt="Back" />
                </div>
                <div className={styles.friends_title}>{'friends'.tr()}</div>
                <div
                    className={styles.add_friend_button}
                    onClick={onShareFriendLinkClick}
                >
                    <img src={Images.Icons.GreenSmallPlus} alt="Add" />
                </div>
            </div>
            <div className={styles.friends_list}>
                {friendsComponent?.friends.map((friend) => (
                    <FriendCard
                        cardType={FriendCardType.Friend}
                        key={friend.username}
                        friend={friend}
                        online={
                            friendsComponent?.isOnline(friend.phoneNumber!) ??
                            false
                        }
                        friendComponent={friendsComponent}
                    />
                ))}
                {friendsComponent?.incomingFriendRequests.map((friend) => (
                    <FriendCard
                        cardType={FriendCardType.IncomingRequest}
                        key={friend.username}
                        friend={friend}
                        online={
                            friendsComponent?.isOnline(friend.phoneNumber!) ??
                            false
                        }
                        friendComponent={friendsComponent}
                    />
                ))}
                {friendsComponent?.outgoingFriendRequests.map((friend) => (
                    <FriendCard
                        cardType={FriendCardType.OutgoingRequest}
                        key={friend.username}
                        friend={friend}
                        online={
                            friendsComponent?.isOnline(friend.phoneNumber!) ??
                            false
                        }
                        friendComponent={friendsComponent}
                    />
                ))}
                {/*    Share friend link button */}
                <div
                    className={styles.share_friend_link_container}
                    onClick={onShareFriendLinkClick}
                >
                    <div className={styles.share_friend_link_text}>
                        {'shareFriendLink'.tr()}
                    </div>
                    <img
                        className={styles.share_friend_link_image}
                        src={Images.Icons.GreenSmallPlus}
                        alt="Share"
                    />
                </div>
            </div>
        </div>
    );
};

export default FriendsPage;
