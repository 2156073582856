import React from 'react';
import styles from './quest_item.module.css';
import coinIcon from '../../../assets/images/navigation_tab/btn-vault.png';
import Task from '../../../apis/models/task/task';

type QuestItemProps = {
    task: Task;
    action: ()=>Promise<void>;
  };

const QuestItem = ({task, action} : QuestItemProps) => {
    return (
        <div className={styles.questItem} onClick={action}>
            <h1 className={styles.questTitle}>{task.title}</h1>
            <p className={styles.questDescription}>{task.description}</p>
            <p className={styles.questDescription}>{task.status}</p>
            <div className={styles.questSpacer} />
            <div className={styles.questFooter}>
                <img src={coinIcon} alt="Coin Stack" width="40" height="40" />
                <div style={{ width: 10 }} />
                <span className={styles.questReward}>{task.tokens} {task.tokenName}</span>
            </div>
        </div>
    );
};

export default QuestItem;
