import StandardHttpAPI from '../standard_http_api';
import ValueContainer from '../../utils/value_container';
import { constants } from '../../utils/constants';
import FriendInfo from '../models/friend/friend_info';
import Friend from '../models/friend/friend';

const API_URL = constants.API_URL;

class FriendApi {
    static async getFriendsInfo(phoneNumbers: string[]): Promise<FriendInfo[]> {
        const response = await StandardHttpAPI.post({
            uri: `${API_URL}friend/GetFriendsInfo`,
            bearer: ValueContainer.token,
            body: {
                PhoneNumbers: phoneNumbers,
            },
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return response.response.map((json: any) =>
            FriendInfo.fromJsonLowerCamelCase(json)
        );
    }

    static async getFriendInfo(phoneNumber: string): Promise<FriendInfo> {
        const response = await StandardHttpAPI.post({
            uri: `${API_URL}friend/GetFriendInfo`,
            bearer: ValueContainer.token,
            body: {
                PhoneNumber: phoneNumber,
            },
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return FriendInfo.fromJsonLowerCamelCase(response.response);
    }

    static async getFriends(): Promise<Friend[]> {
        const response = await StandardHttpAPI.get({
            uri: `${API_URL}friend/GetFriends`,
            bearer: ValueContainer.token,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return response.response.map((json: any) =>
            Friend.fromJsonLowerCamelCase(json)
        );
    }

    static async getIncomingFriendRequests(): Promise<Friend[]> {
        const response = await StandardHttpAPI.get({
            uri: `${API_URL}friend/GetIncomingFriendRequests`,
            bearer: ValueContainer.token,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return response.response.map((json: any) =>
            Friend.fromJsonLowerCamelCase(json)
        );
    }

    static async getOutgoingFriendRequests(): Promise<Friend[]> {
        const response = await StandardHttpAPI.get({
            uri: `${API_URL}friend/GetOutgoingFriendRequests`,
            bearer: ValueContainer.token,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return response.response.map((json: any) =>
            Friend.fromJsonLowerCamelCase(json)
        );
    }

    static async sendFriendRequest(phoneNumber: string): Promise<Friend> {
        const response = await StandardHttpAPI.post({
            uri: `${API_URL}friend/SendFriendRequest`,
            bearer: ValueContainer.token,
            body: {
                PhoneNumber: phoneNumber,
            },
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return Friend.fromJsonLowerCamelCase(response.response);
    }

    static async removeFriend(phoneNumber: string): Promise<Friend> {
        const response = await StandardHttpAPI.post({
            uri: `${API_URL}friend/RemoveFriend`,
            bearer: ValueContainer.token,
            body: {
                PhoneNumber: phoneNumber,
            },
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return Friend.fromJsonLowerCamelCase(response.response);
    }

    static async acceptFriendRequest(phoneNumber: string): Promise<Friend> {
        const response = await StandardHttpAPI.post({
            uri: `${API_URL}friend/AcceptFriendRequest`,
            bearer: ValueContainer.token,
            body: {
                PhoneNumber: phoneNumber,
            },
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return Friend.fromJsonLowerCamelCase(response.response);
    }

    static async getFriendsConnectionStatus(): Promise<Map<string, boolean>> {
        const response = await StandardHttpAPI.get({
            uri: `${API_URL}friend/GetFriendsConnectionStatus`,
            bearer: ValueContainer.token,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return new Map(Object.entries(response.response));
    }

    /**
     * Looks up a username based on a Telegram ID.
     * @param telegramId The Telegram ID to look up.
     * @returns A promise resolving to the username or rejecting on failure.
     */
    static async lookupUsernameByTelegramId(
        telegramId: string
    ): Promise<string> {
        const response = await StandardHttpAPI.get({
            uri: `${API_URL}friend/LookupUsernameByTelegramId/${telegramId}`,
            bearer: ValueContainer.token,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return response.response.username;
    }
}

export default FriendApi;
