class Referral {
    referredId: string;
    username: string;
    referralDate: Date;
    pointsEarned: number;    

    constructor({
        referredId,
        username,
        referralDate,
        pointsEarned,
       
    }: {
        referredId: string;
        username: string;
        referralDate: Date;
        pointsEarned: number;
        
    }) {
        this.referredId = referredId;
        this.username = username;
        this.referralDate = new Date(referralDate);
        this.pointsEarned = pointsEarned;
    }

    static fromJsonLowerCamelCase(json: any): Referral {
        return new Referral({
            referredId: json.referredId,
            username: json.username,
            referralDate: new Date(json.referralDate),
            pointsEarned: json.pointsEarned,
         
        });
    }

    static fromJsonUpperCase(json: any): Referral {
        return new Referral({
            referredId: json.ReferredId,
            username: json.Username,
            referralDate: new Date(json.ReferralDate),
            pointsEarned: json.PointsEarned,
        });
    }
}

export default Referral;