import Pet from './pet';

class FriendInfo {
    username: string;
    phoneNumber: string;
    telegramId: string;
    selectedCharacter: string;
    shouldRender: boolean;
    latitude: number;
    longitude: number;
    activePets: Pet[];
    // isMale: boolean;
    // characterIndex: number;

    constructor({
        username,
        phoneNumber,
        telegramId,
        selectedCharacter,
        shouldRender,
        latitude,
        longitude,
        activePets,
    }: {
        username: string;
        phoneNumber: string;
        telegramId: string;
        selectedCharacter: string;
        shouldRender: boolean;
        latitude: number;
        longitude: number;
        activePets: Pet[];
    }) {
        this.username = username;
        this.phoneNumber = phoneNumber;
        this.telegramId = telegramId;
        this.selectedCharacter = selectedCharacter;
        this.shouldRender = shouldRender;
        this.latitude = latitude;
        this.longitude = longitude;
        this.activePets = activePets;
        // this.isMale = selectedCharacter.split('_')[0] === 'male';
        // this.characterIndex = parseInt(selectedCharacter.split('_')[1]);
    }

    static fromJsonLowerCamelCase(json: any): FriendInfo {
        return new FriendInfo({
            username: json.username,
            phoneNumber: json.phoneNumber,
            telegramId: json.telegramId,
            selectedCharacter: json.selectedCharacter,
            shouldRender: json.shouldRender,
            latitude: json.latitude,
            longitude: json.longitude,
            activePets: json.activePets.map((e: any) =>
                Pet.fromJsonLowerCamelCase(e)
            ),
        });
    }

    static fromJsonUpperCase(json: any): FriendInfo {
        return new FriendInfo({
            username: json.Username,
            phoneNumber: json.PhoneNumber,
            telegramId: json.TelegramId,
            selectedCharacter: json.SelectedCharacter,
            shouldRender: json.ShouldRender,
            latitude: json.Latitude,
            longitude: json.Longitude,
            activePets: json.ActivePets.map((e: any) =>
                Pet.fromJsonUpperCase(e)
            ),
        });
    }
}

export default FriendInfo;
