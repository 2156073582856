import React from 'react';
import { confirmable, ConfirmDialog, createConfirmation } from 'react-confirm';
import ReactModal from 'react-modal';
import styles from './confirm_dialog.module.css';
import Images from '../../component_system/components/preload/images';

interface _ConfirmDialogProps {
    title: string;
    message: string;
    onConfirm: () => boolean;
    onCancel: () => void;
    show: boolean;
    isInput: boolean;
    onConfirmWithInput: (input: string) => boolean;
    cancellable: boolean;
    dismissable: boolean;
    inputError?: string;
    inputPattern?: RegExp;
}

const CustomConfirmDialog: React.FC<_ConfirmDialogProps> = (props) => {
    const [input, setInput] = React.useState('');

    return (
        <ReactModal
            style={{
                overlay: {
                    zIndex: 3,
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                },
                content: {
                    height: 'fit-content',
                    backgroundImage: `url(${Images.Backgrounds.Background})`,
                    borderColor: 'var(--primary-color)',
                },
            }}
            isOpen={props.show}
            onRequestClose={props.onCancel}
            shouldCloseOnOverlayClick={props.dismissable}
        >
            <h1 className={styles.overlay_content_title}>{props.title}</h1>
            <p
                className={
                    props.isInput
                        ? styles.overlay_content_message_above_input
                        : styles.overlay_content_message
                }
            >
                {props.message}
            </p>
            {props.isInput && (
                <>
                    <input
                        className={styles.overlay_content_input}
                        type="text"
                        placeholder={'type'.tr()}
                        value={input}
                        pattern={props.inputPattern?.source}
                        onChange={(e) => {
                            // check if input matches pattern
                            if (props.inputPattern) {
                                if (!props.inputPattern.test(e.target.value)) {
                                    return;
                                }
                            }
                            setInput(e.target.value);
                        }}
                    />
                    {props.inputError && (
                        <p className={styles.overlay_content_input_error}>
                            {props.inputError}
                        </p>
                    )}
                </>
            )}
            <div className={styles.overlay_content_buttons}>
                <button
                    className={styles.overlay_content_confirm_button}
                    onClick={
                        props.isInput
                            ? () => props.onConfirmWithInput(input)
                            : props.onConfirm
                    }
                >
                    {'confirm'.tr()}
                </button>
                {props.cancellable && (
                    <button
                        onClick={props.onCancel}
                        className={styles.overlay_content_cancel_button}
                    >
                        {'cancel'.tr()}
                    </button>
                )}
            </div>
        </ReactModal>
    );
};

type ConfirmDialogProps = {
    options: {
        title: string;
        message: string;
        isInput?: boolean;
        cancellable?: boolean;
        dismissable?: boolean;
        onConfirm?: () => boolean;
        onConfirmWithInput?: (input: string) => [boolean, string | undefined];
        inputPattern?: RegExp;
        initialInputError?: string;
    };
};

export const ConfirmationDialog: ConfirmDialog<
    ConfirmDialogProps,
    boolean | string
> = ({
    show,
    proceed,
    options: {
        title,
        message,
        isInput = false,
        cancellable = true,
        dismissable = true,
        onConfirm = undefined,
        onConfirmWithInput = undefined,
        inputPattern = undefined,
        initialInputError = undefined,
    },
}) => {
    const [inputError, setInputError] = React.useState<string | undefined>(
        initialInputError
    );

    return (
        <CustomConfirmDialog
            title={title}
            message={message}
            show={show}
            isInput={isInput}
            onConfirmWithInput={(input) => {
                if (onConfirmWithInput) {
                    if (onConfirmWithInput(input)[0]) {
                        proceed(input);
                    } else {
                        setInputError(onConfirmWithInput(input)[1]);
                        return false;
                    }
                }
                proceed(input);
                return true;
            }}
            onConfirm={() => {
                if (onConfirm) {
                    if (onConfirm()) {
                        proceed(true);
                    }
                }
                proceed(true);
                return true;
            }}
            onCancel={() => proceed(false)}
            cancellable={cancellable}
            dismissable={dismissable}
            inputError={inputError}
            inputPattern={inputPattern}
        />
    );
};

const confirmDialog = confirmable(ConfirmationDialog);

export const confirm = createConfirmation(confirmDialog);
