import styles from './user_avatar.module.css';
import ValueContainer from '../../utils/value_container';
import useObservable from '../../utils/observable/use_observable_hook';

type UserAvatarProps = {
    size: number;
    borderRadius?: number;
    borderColor?: string;
    borderWidth?: number;
    backgroundColor?: string;
    focusOnHead?: boolean;
    showLevel?: boolean;
    shadow?: string;
    levelTextColor?: string;
    imageUri?: string;
};

// TODO: move styling to separate file
const UserAvatar = ({
    size,
    borderRadius = size / 2,
    borderColor = 'var(--gold-color)',
    borderWidth = 2,
    backgroundColor = 'var(--primary-color)',
    focusOnHead = true,
    showLevel = true,
    shadow = undefined,
    levelTextColor = 'white',
    imageUri,
}: UserAvatarProps) => {
    const currentLevel = useObservable(ValueContainer.currentLevelObservable);
    const characterImageBase64 = useObservable(
        ValueContainer.characterImageBase64Observable
    );

    const addDefaultSrc = (event: any) => {
        event.target.src = `data:image/png;base64,${characterImageBase64}`;
    };

    return (
        <div
            className={styles.userAvatar}
            style={{
                width: size,
                height: size,
                borderRadius,
                borderColor,
                borderWidth,
                backgroundColor,
                borderStyle: 'solid',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                overflow: 'hidden',
                boxShadow: shadow ?? 'none',
            }}
        >
            {characterImageBase64 && (
                <img
                    src={
                        imageUri ||
                        `data:image/png;base64,${characterImageBase64}`
                    }
                    onError={addDefaultSrc}
                    alt="User avatar"
                    style={{
                        width: focusOnHead ? '250%' : '100%',
                        height: focusOnHead ? 'auto' : '100%',
                        objectPosition: focusOnHead
                            ? 'center top'
                            : 'center center',
                        objectFit: 'cover',
                        transform: focusOnHead ? 'translate(4%, -9%)' : 'none',
                        borderRadius,
                    }}
                />
            )}
            {showLevel && (
                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        color: levelTextColor,
                        fontSize: size / 3,
                        height: size / 3,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textShadow: '0 0 5px #000',
                        transform: 'translateY(20%)',
                    }}
                >
                    {currentLevel ?? 1}
                </div>
            )}
        </div>
    );
};

export default UserAvatar;
