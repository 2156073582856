import PetMode from './pet_mode';
import PositionModel from '../position_model';

class Pet {
    petId: string;
    petClass: string;
    ownerUsername: string;
    ownerTelegramId: string;
    name: string;
    isActivated: boolean;
    mode: PetMode;
    position: PositionModel;
    targetPosition: PositionModel;
    targetPositionEstimatedArrivalTime: Date;
    isSeen: boolean;

    constructor({
        petId,
        petClass,
        ownerUsername,
        ownerTelegramId,
        name,
        isActivated,
        mode,
        position,
        targetPosition,
        targetPositionEstimatedArrivalTime,
        isSeen,
    }: {
        petId: string;
        petClass: string;
        ownerUsername: string;
        ownerTelegramId: string;
        name: string;
        isActivated: boolean;
        mode: PetMode;
        position: PositionModel;
        targetPosition: PositionModel;
        targetPositionEstimatedArrivalTime: Date;
        isSeen: boolean;
    }) {
        this.petId = petId;
        this.petClass = petClass;
        this.ownerUsername = ownerUsername;
        this.ownerTelegramId = ownerTelegramId;
        this.name = name;
        this.isActivated = isActivated;
        this.mode = mode;
        this.position = position;
        this.targetPosition = targetPosition;
        this.targetPositionEstimatedArrivalTime =
            targetPositionEstimatedArrivalTime;
        this.isSeen = isSeen;
    }

    static fromJsonLowerCamelCase(json: any): Pet {
        return new Pet({
            petId: json.petId,
            petClass: json.petClass,
            ownerUsername: json.ownerUsername,
            ownerTelegramId: json.ownerTelegramId,
            name: json.name,
            isActivated: json.isActivated,
            mode: PetMode[json.mode as keyof typeof PetMode],
            position: PositionModel.fromJson(json.position),
            targetPosition: PositionModel.fromJson(json.targetPosition),
            targetPositionEstimatedArrivalTime: new Date(
                json.targetPositionEstimatedArrivalTime
            ),
            isSeen: json.isSeen,
        });
    }

    static fromJsonUpperCase(json: any): Pet {
        return new Pet({
            petId: json.PetId,
            petClass: json.PetClass,
            ownerUsername: json.OwnerUsername,
            ownerTelegramId: json.OwnerTelegramId,
            name: json.Name,
            isActivated: json.IsActivated,
            mode: PetMode[json.Mode as keyof typeof PetMode],
            position: PositionModel.fromJsonUpperCase(json.Position),
            targetPosition: PositionModel.fromJsonUpperCase(
                json.TargetPosition
            ),
            targetPositionEstimatedArrivalTime: new Date(
                json.TargetPositionEstimatedArrivalTime
            ),
            isSeen: json.IsSeen,
        });
    }
}

export default Pet;
