import styles from './quests_page.module.css';
import arrowBackIcon from '../../assets/images/icons/arrow_back.png';
import backgroundImg from '../../assets/images/backgrounds/background-ani.png';
import inviteBanner from '../../assets/images/panels/invite-banner.png';
import QuestItem from '../components/quests/quest_item';
import { constants } from '../../utils/constants';
import {
    init,
    openLink,
    openTelegramLink,
    shareURL,
} from '@telegram-apps/sdk-react';
import ValueContainer from '../../utils/value_container';
import useFetchComponent from '../../component_system/component_container/utilities/use_fetch_hook';
import ContainerHelper from '../../component_system/component_container/utilities/container_helper';
import Images from '../../component_system/components/preload/images';
import React, { useEffect, useRef, useState } from 'react';
import QuestComponent from '../../component_system/components/quest/quest_component';
import Task from '../../apis/models/task/task';
import TaskStatus from '../../apis/models/task/task_status';
import TaskType from '../../apis/models/task/task_type';
import { toast } from 'react-toastify';

const REFER_URL_STRUCTURE = constants.REFER_URL_STRUCTURE;

const QuestsPage = () => {
    const navigationComponent = useFetchComponent(
        ContainerHelper.getNavigationComponent
    );
    const [tasks, setTasks] = useState<Task[]>([]);
    const [referralCount, setReferralCount] = useState<number>(0);
    const [referralPointsEarned, setReferralPointsEarned] = useState<number>(0);
    const questComponentRef = useRef<QuestComponent | null>(null);

    init();

    useEffect(() => {
        const subscriber = () => {
            // Update tasks when notified
            if (questComponentRef.current) {
                setTasks([...questComponentRef.current.tasks]);
                setReferralCount(questComponentRef.current.referralsCount);
                setReferralPointsEarned(
                    questComponentRef.current.referralPointsEarned
                );
            }
        };

        const setup = async () => {
            questComponentRef.current =
                await ContainerHelper.getQuestComponent();
            // Subscribe to updates
            questComponentRef.current.addSubscriber(subscriber);
            // Set initial tasks
            setTasks([...questComponentRef.current.tasks]);
            setReferralCount(questComponentRef.current.referralsCount);
            setReferralPointsEarned(
                questComponentRef.current.referralPointsEarned
            );
        };

        setup();

        // Cleanup on unmount
        return () => {
            if (questComponentRef.current) {
                questComponentRef.current.removeSubscriber(subscriber);
            }
        };
    }, []);
    {
        /*
    const quests: Task[] = [
        {
            title: 'Join Telegram',
            description: 'Join DIGGERS GO Telegram channel',
            action: () => console.log('Join Telegram'),
        },
        {
            title: 'Follow Twitter/X',
            description: 'Follow DIGGERS GO on Twitter/X',
            action: () => console.log('Follow Twitter/X'),
        },
        {
            title: 'Refer a friend',
            description: 'Refer a friend to DIGGERS GO',
            action: () => inviteFriends(),
        },
    ];
    */
    }

    const customSafeArea = 60;

    const API_URL = constants.INVITE_URL;
    const inviteFriends = () => {
        const inviteLink = REFER_URL_STRUCTURE.replace(
            '%TID%',
            ValueContainer.telegramId
        );
        if (window.Telegram !== undefined)
            shareURL(inviteLink, 'Join DIGGERS GO and earn rewards!');
    };

    const handleCopyLink = () => {
        const inviteLink = `${API_URL}?startapp=${ValueContainer.telegramId}`;
        navigator.clipboard.writeText(inviteLink);
        toast.info('Invite link copied to clipboard!');
    };

    const handleTelegramLink = (task: Task) => {
        if (openTelegramLink.isAvailable()) {
            openTelegramLink(task.url as string);
        }
    };

    const openUrl = (task: Task) => {
        if (openLink.isAvailable()) {
            openLink(task.url as string, {
                tryBrowser: 'chrome',
                tryInstantView: false,
            });
        } else {
            window.open(task.url as string, '_blank');
        }
    };

    const handleTaskAction = async (task: Task) => {
        if (questComponentRef.current) {
            if (task.status === TaskStatus.NotStarted) {
                if (task.taskType === TaskType.Follow) {
                    openUrl(task);
                    const success = await questComponentRef.current.verifyTask(
                        task.taskId
                    );
                } else if (task.taskType === TaskType.Join) {
                    const success = await questComponentRef.current.startTask(
                        task.taskId
                    );
                    handleTelegramLink(task);
                }
            } else if (task.status === TaskStatus.Started) {
                if (task.taskType === TaskType.Follow) {
                    toast.info('Please wait for us to verify your task');
                }
                if (task.taskType === TaskType.Join) {
                    const success = await questComponentRef.current.verifyTask(
                        task.taskId
                    );
                }
            } else if (task.status === TaskStatus.Completed) {
                return;
            }
        }
    };

    return (
        <div>
            <div className={styles.pageBg}>
                <img
                    src={backgroundImg}
                    className={styles.animatedImage}
                    alt=""
                />
            </div>
            {/*
            <div className={styles.quests_header}>
                <div
                    className={styles.back_button}
                    onClick={() => navigationComponent?.goBack()}
                >
                    <img src={Images.Icons.ArrowBack} alt="Back" />
                </div>
                <div className={styles.quests_title}>{'quests'.tr()}</div>
            </div>
            */}

            <div className={styles.pageContent}>
                <h2 className={styles.pageTitle}>Quests</h2>

                <div
                    className={styles.referralPanel}
                    style={{ backgroundImage: `url(${inviteBanner})` }}
                >
                    <div className={styles.buttonContainer}>
                        <button
                            type="button"
                            onClick={inviteFriends}
                            className={styles.refBtn}
                            name="button"
                        >
                            Invite
                        </button>
                        <button
                            type="button"
                            className={`${styles.refBtn} ${styles.last}`}
                            name="button"
                            onClick={handleCopyLink}
                        >
                            Copy link
                        </button>
                    </div>                   
                </div>
                <div className={styles.invite_friends}>     
                        
                        <div className={styles.invite_friends_count}>
                            {`(${referralCount})`} {'friends invited'.tr()}
                            {`(${referralPointsEarned})`} {'stones earned'.tr()}
                        </div>
                </div>

                <div className={styles.questPanel}>
                    <h3 className={styles.title}>Join telegram</h3>
                    <p>Join diggesgo announcements telegram group</p>
                </div>



                {tasks.map((quest: Task, index) => (
                    <div key={index}>
                        {index === 0 && (
                            <div style={{ height: customSafeArea / 10 }} />
                        )}
                        <QuestItem
                            task={quest}
                            action={() => handleTaskAction(quest)}
                        />
                        <div style={{ height: customSafeArea / 10 }} />
                        {index === tasks.length - 1 && (
                            <div style={{ height: customSafeArea }} />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default QuestsPage;
