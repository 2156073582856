import { TimeDuration } from 'typed-duration';
import Component from '../../component_container/models/component';
import ComponentError from '../../component_container/models/component_error';
import Pet from '../../../apis/models/friend/pet';
import ValueContainer from '../../../utils/value_container';
import AuthenticationComponent from '../authentication/authentication_component';
import CustomizationApi from '../../../apis/customization_api/customization_api';
import ComponentErrorType from '../../component_container/enums/component_error_type';
import FriendInfo from '../../../apis/models/friend/friend_info';
import UnityComponent from '../unity/unity_component';

type CustomizationComponentSubscriber = () => void;

class CustomizationComponent extends Component {
    private _subscribers: CustomizationComponentSubscriber[] = [];

    addSubscriber(subscriber: CustomizationComponentSubscriber): void {
        this._subscribers.push(subscriber);
    }

    removeSubscriber(subscriber: CustomizationComponentSubscriber): void {
        this._subscribers = this._subscribers.filter((s) => s !== subscriber);
    }

    private _notifySubscribers(): void {
        this._subscribers.forEach((subscriber) => subscriber());
    }

    private _characterBuildString: string | undefined;
    get characterBuildString(): string | undefined {
        return this._characterBuildString;
    }

    private _pets: Pet[] | undefined;
    get pets(): Pet[] {
        return this._pets!;
    }
    get activePets(): Pet[] {
        return this._pets!.filter((pet) => pet.isActivated);
    }
    get userActivePets(): Pet[] {
        return this.activePets.filter(
            (pet) => pet.ownerTelegramId === ValueContainer.telegramId
        );
    }
    get userPets(): Pet[] {
        return this._pets!.filter(
            (pet) => pet.ownerTelegramId === ValueContainer.telegramId
        );
    }
    get unseenPetCount(): number {
        return this.userPets.filter((pet) => !pet.isSeen).length;
    }

    removePetsBelongingTo(telegramId: string): void {
        this._pets = this._pets!.filter(
            (pet) => pet.ownerTelegramId !== telegramId
        );
        this._notifySubscribers();
    }

    removePetsBelongingToWithoutNotify(telegramId: string): void {
        this._pets = this._pets!.filter(
            (pet) => pet.ownerTelegramId !== telegramId
        );
    }

    removePet(petId: string): void {
        this._pets = this._pets!.filter((pet) => pet.petId !== petId);
        this._notifySubscribers();
    }

    removePetWithoutNotify(petId: string): void {
        this._pets = this._pets!.filter((pet) => pet.petId !== petId);
    }

    get type(): Function {
        return CustomizationComponent;
    }
    get name(): string {
        return 'Customization Component';
    }
    async load(): Promise<ComponentError[]> {
        await this.setDependencyLocked([AuthenticationComponent]);
        try {
            this._pets = await CustomizationApi.getPets();
            this._characterBuildString =
                await CustomizationApi.getSelectedCharacter();
        } catch (error) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'customizationComponentFailedToLoad'.tr()
                ),
            ];
        }
        await this.setDependencyLocked([UnityComponent]);
        const unityComponent = (await this.getComponent(
            UnityComponent
        )) as UnityComponent;

        unityComponent.setCharacter(this._characterBuildString!);

        await new Promise((resolve) => setTimeout(resolve, 50));

        ValueContainer.characterImageBase64 =
            await unityComponent.generateCharacterPreview();

        try {
            const shouldUpdateAvatarImage =
                await CustomizationApi.shouldUpdateUserAvatar();

            if (shouldUpdateAvatarImage) {
                // convert from base64 to png
                await this._uploadBase64Image(
                    ValueContainer.characterImageBase64
                );
            }
        } catch (error) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'customizationComponentFailedToLoad'.tr()
                ),
            ];
        }

        return [];
    }
    async onUnload(): Promise<void> {}
    async onPause(): Promise<void> {}
    async onResume(): Promise<void> {}
    update(sinceLastUpdate: TimeDuration): void {}

    async deactivatePet(petId: string): Promise<boolean> {
        const response = await CustomizationApi.deactivatePet(petId);
        if (!response.isSuccess) {
            return false;
        }
        this._pets = this._pets!.map((pet) =>
            pet.petId === petId ? { ...pet, isActivated: false } : pet
        );
        this._notifySubscribers();
        return true;
    }

    async activatePet(petId: string): Promise<boolean> {
        const response = await CustomizationApi.activatePet(petId).catch(
            (e) => undefined
        );
        if (!response) {
            return false;
        }
        this._updateOrAddPet(response!);
        return true;
    }

    private _updateOrAddPet(pet: Pet): void {
        let existingPet: Pet | undefined;
        existingPet = this._pets!.find((p) => p.petId === pet.petId);
        if (existingPet) {
            existingPet.isActivated = pet.isActivated;
            existingPet.position = pet.position;
            existingPet.targetPosition = pet.targetPosition;
        } else {
            this._pets!.push(pet);
        }
        this._notifySubscribers();
    }

    private _updateOrAddWithoutNotify(pet: Pet): void {
        let existingPet: Pet | undefined;
        existingPet = this._pets!.find((p) => p.petId === pet.petId);
        if (existingPet) {
            existingPet.isActivated = pet.isActivated;
            existingPet.position = pet.position;
            existingPet.targetPosition = pet.targetPosition;
        } else {
            this._pets!.push(pet);
        }
    }

    updatePets(friendTelegramIds: string[], onlineFriendInfos: FriendInfo[]) {
        const offlineFriendTelegramIds = friendTelegramIds.filter(
            (telegramId) =>
                !onlineFriendInfos.find(
                    (info) => info.telegramId === telegramId
                )
        );

        offlineFriendTelegramIds.forEach((telegramId) => {
            this.removePetsBelongingToWithoutNotify(telegramId);
        });

        const activePets = onlineFriendInfos
            .map((info) => info.activePets)
            .flat();

        activePets.forEach((pet) => {
            this._updateOrAddWithoutNotify(pet);
        });

        for (const friendInfo of onlineFriendInfos) {
            const currentActivePets = this._activePetsOf(friendInfo.telegramId);
            for (const pet of currentActivePets) {
                if (!friendInfo.activePets.find((p) => p.petId === pet.petId)) {
                    this.removePetWithoutNotify(pet.petId);
                }
            }
        }

        this._notifySubscribers();
    }

    _activePetsOf(telegramId: string): Pet[] {
        return this.activePets.filter(
            (pet) => pet.ownerTelegramId === telegramId
        );
    }

    async namePet(petId: string, name: string): Promise<boolean> {
        const response = await CustomizationApi.namePet(petId, name);
        if (!response.isSuccess) {
            return false;
        }
        this._pets = this._pets!.map((pet) =>
            pet.petId === petId ? { ...pet, name } : pet
        );
        this._notifySubscribers();
        return true;
    }

    private async _uploadBase64Image(base64: string) {
        // Check if a prefix exists and strip it if present
        const base64Data = base64.includes(',') ? base64.split(',')[1] : base64;

        // Convert base64 string to Blob
        const byteCharacters = atob(base64Data);
        const byteArrays = [];
        for (let i = 0; i < byteCharacters.length; i += 512) {
            const slice = byteCharacters.slice(i, i + 512);
            const byteNumbers = new Array(slice.length);
            for (let j = 0; j < slice.length; j++) {
                byteNumbers[j] = slice.charCodeAt(j);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: 'image/png' });

        // Create a File object from the Blob
        const file = new File([blob], 'avatar.png', { type: 'image/png' });

        // Upload the file using CustomizationApi
        await CustomizationApi.uploadUserAvatar(file);
    }
}

export default CustomizationComponent;
