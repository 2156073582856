import Component from '../../component_container/models/component';
import ComponentError from '../../component_container/models/component_error';
import { TimeDuration } from 'typed-duration';
import TelegramApi from '../../../apis/telegram_api';
import TelegramValidationResult from './telegram_vaildation_result';
import ComponentErrorType from '../../component_container/enums/component_error_type';

import { search_params_keys } from '../../../utils/constants';
import UsernameValidationResult from './username_validation_result';
import ContainerHelper from '../../component_container/utilities/container_helper';
import { confirm } from '../../../ui/components/confirm_dialog';
import { toast } from 'react-toastify';

class TelegramComponent extends Component {
    private _usernameCreationParameter?: string;
    private _usernameAlreadyExistsError = false;
    private _authToken?: string;

    get authToken(): string | undefined {
        return this._authToken;
    }

    set usernameCreationParameter(value: string | undefined) {
        this._usernameCreationParameter = value;
    }

    async load(): Promise<Array<ComponentError>> {
        window.Telegram.WebApp.enableClosingConfirmation();
        window.Telegram.WebApp.disableVerticalSwipes();
        window.Telegram.WebApp.expand();
        window.Telegram.WebApp.setBackgroundColor('#0048A8');
        window.Telegram.WebApp.setHeaderColor('#0048A8');
        window.Telegram.WebApp.setBottomBarColor('#0048A8');

        const isLandscape = window.innerWidth > window.innerHeight;
        if (isLandscape) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'landscapeModeNotSupported'.tr()
                ),
            ];
        }

        // Lock after we made sure that the screen is in portrait mode
        window.Telegram.WebApp.lockOrientation();

        const navComponent = ContainerHelper.forceGetNavigationComponent();
        const initialSearchParams =
            await navComponent.initialSearchParamsCompleter.promise;

        const friend =
            initialSearchParams.get(
                search_params_keys.SEARCH_PARAMS_KEY_FRIEND_REFERER
            ) || undefined;
        const referer =
            initialSearchParams.get(
                search_params_keys.SEARCH_PARAMS_KEY_REFERER
            ) || undefined;

        const initData = window.Telegram.WebApp.initData;
        const startParam = friend || referer;

        if (!initData) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'telegramInitializationDataNotFound'.tr()
                ),
            ];
        }

        let validationResult = await this.validate(
            initData,
            this._usernameCreationParameter,
            startParam
        );

        if (!validationResult.isValid) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    validationResult.message || 'unknownError'.tr()
                ),
            ];
        }

        let token = validationResult.token;

        while (!token) {
            this._usernameCreationParameter = (await confirm({
                options: {
                    title: 'registerToBegin'.tr(),
                    message: 'username'.tr(),
                    isInput: true,
                    cancellable: false,
                    dismissable: false,
                    // alphanumeric with underscore
                    inputPattern: /^[a-zA-Z0-9_]*$/,
                    onConfirmWithInput: (username: string) => {
                        if (username.length < 8) {
                            return [
                                false,
                                'notEnoughCharactersError'
                                    .tr()
                                    .replace('{}', '8'),
                            ];
                        }

                        return [true, undefined];
                    },
                    initialInputError: this._usernameAlreadyExistsError
                        ? 'usernameAlreadyTaken'.tr()
                        : undefined,
                },
            })) as string;
            validationResult = await this.validate(
                initData,
                this._usernameCreationParameter,
                startParam
            );

            if (!validationResult.isValid) {
                if (validationResult.message === 'USERNAME_ALREADY_EXISTS') {
                    this._usernameCreationParameter = undefined;
                    this._usernameAlreadyExistsError = true;
                    continue;
                }

                return [
                    new ComponentError(
                        ComponentErrorType.LoadError,
                        validationResult.message || 'unknownError'.tr()
                    ),
                ];
            }

            token = validationResult.token;

            if (token) {
                toast.success('success'.tr());
            }

            // return [
            //     new ComponentError(
            //         ComponentErrorType.LoadError,
            //         'tokenNotFoundInValidationResponse'.tr(),
            //         undefined,
            //         component_error_codes.TELEGRAM_REGISTRATION_INCOMPLETE
            //     ),
            // ]; // TODO: tr()
        }

        this._authToken = token;

        return [];
    }

    async validate(
        initData: string,
        username?: string,
        referrerTelegramId?: string
    ): Promise<TelegramValidationResult> {
        const response = await TelegramApi.validate(
            initData,
            username,
            referrerTelegramId
        );
        if (response.isSuccess) {
            return new TelegramValidationResult({
                isValid: true,
                token: response.response['token'],
            });
        } else {
            return new TelegramValidationResult({
                isValid: false,
                message: response.errorMessage || 'unknownError'.tr(),
            }); // TODO: tr()
        }
    }

    async validateUsername(
        username: string
    ): Promise<UsernameValidationResult> {
        const response = await TelegramApi.validateUsername(username);
        if (response.isSuccess) {
            return new UsernameValidationResult({
                isValid: true,
            });
        } else {
            return new UsernameValidationResult({
                isValid: false,
                message: response.errorMessage || 'unknownError'.tr(),
            });
        }
    }

    get name(): string {
        return 'Telegram Component';
    }

    onPause(): Promise<void> {
        return Promise.resolve(undefined);
    }

    onResume(): Promise<void> {
        return Promise.resolve(undefined);
    }

    onUnload(): Promise<void> {
        return Promise.resolve(undefined);
    }

    update(sinceLastUpdate: TimeDuration): void {}

    get type(): Function {
        return TelegramComponent;
    }
}

export default TelegramComponent;
