import React from 'react';
import CurrencyIcon, { CurrencyType } from '../currency_icon';

interface TooFarAwayFadeOutProps {
    backgroundColor?: string; // Text and icon color
    textColor?: string; // Text color
    fontSize?: number; // Font size of the amount
}

const TooFarAwayFadeOut: React.FC<TooFarAwayFadeOutProps> = ({
    backgroundColor = '#F44336',
    // 0xFFF44336
    textColor = 'var(--white-color)',
    fontSize = 12,
}) => {
    return (
        <div
            style={{
                padding: '4px',
                borderRadius: '5px',
                backgroundColor: backgroundColor,
                display: 'flex',
                alignItems: 'center',
                color: textColor,
            }}
        >
            <p
                style={{
                    margin: '0',
                    fontSize: `${fontSize}px`,
                    fontWeight: 'bold',
                }}
            >
                {'tooFarAwayFromBubble'.tr()}
            </p>
        </div>
    );
};

export default TooFarAwayFadeOut;
