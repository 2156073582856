import React, { useEffect, useRef, useState } from 'react';
import styles from './vault_page.module.css';
import backgroundImg from '../../assets/images/backgrounds/background-ani.png';
import tokenImg from '../../assets/images/icons/token-icon.png';
import useFetchComponent from '../../component_system/component_container/utilities/use_fetch_hook';
import ContainerHelper from '../../component_system/component_container/utilities/container_helper';
import OfferComponent from '../../component_system/components/offer/offer_component';
import GroupedOffer from '../../apis/offer_api/grouped_offer';

const VaultPage = () => {
    const navigationComponent = useFetchComponent(
        ContainerHelper.getNavigationComponent
    );

    const [groupedOffer, setGroupedOffer] = useState<GroupedOffer[]>([]);
    const offerComponentRef = useRef<OfferComponent | null>(null);

    useEffect(() => {
        const subscriber = () => {
            // Update tasks when notified
            if (offerComponentRef.current) {
                setGroupedOffer([...offerComponentRef.current.groupedOffers]);
            }
        };

        const setup = async () => {
            offerComponentRef.current =
                await ContainerHelper.getOffersComponent();
            // Subscribe to updates
            offerComponentRef.current.registerSubscriber(subscriber);
            // Set initial tasks
            setGroupedOffer([...offerComponentRef.current.groupedOffers]);
        };

        setup();

        // Cleanup on unmount
        return () => {
            if (offerComponentRef.current) {
                offerComponentRef.current.unregisterSubscriber(subscriber);
            }
        };
    }, []);

    function claimAction(offer: GroupedOffer): void {
        throw new Error('Function not implemented.');
    }

    function formatNumber(value: number): string {
        if (value >= 1_000_000) {
            return (value / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M';
        } else if (value >= 1_000) {
            return (value / 1_000).toFixed(1).replace(/\.0$/, '') + 'K';
        } else {
            return value.toString();
        }
    }

    return (
        <div>
            <div className={styles.pageBg}>
                <img
                    src={backgroundImg}
                    className={styles.animatedImage}
                    alt=""
                />
            </div>
            {/*
            <div className={styles.rewards_header}>
                <div
                    className={styles.back_button}
                    onClick={() => navigationComponent?.goBack()}
                >
                    <img src={Images.Icons.ArrowBack} alt="Back" />
                </div>
                <div className={styles.rewards_title}>{'rewards'.tr()}</div>
            </div> */}

            <div className={styles.pageContent}>
                <h2 className={styles.pageTitle}>Vault</h2>

                {groupedOffer.length > 0 ? (
                    groupedOffer.map((offer, index) => (
                        <div
                            key={index}
                            className={`${styles.questPanel} ${styles.vault} ${offer.offerName === undefined ?styles.nOCoin : ''}`}
                        >
                            <img
                                src={tokenImg}
                                className={styles.icon}
                                alt=""
                            />
                            <h5 className={styles.tokenTitle}>
                                {offer.offerName}
                            </h5>
                            <div className={styles.actions}>
                                <p>{formatNumber(offer.totalCoinWorth)}</p>
                                <button
                                    type="button"
                                    className={styles.claim}
                                    onClick={() => claimAction(offer)}
                                >
                                    Claim
                                </button>
                            </div>
                        </div>
                    ))
                ) : (
                    <div>
                        {'You have not collected any items yet'.tr()}
                    </div>
                )}
            </div>
        </div>
    );
};

export default VaultPage;
