import React, {
    memo,
    useMemo,
    useCallback,
    useEffect,
    useState,
    useRef,
} from 'react';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadSlim } from '@tsparticles/slim';
import { loadEmittersPlugin } from '@tsparticles/plugin-emitters';
import { Container, InteractivityDetect, OutMode } from '@tsparticles/engine';
import styles from './dig_window.module.css';

const ParticlesComponent = memo(() => {
    const [init, setInit] = useState<boolean>(false);
    const particlesContainerRef = useRef<Container & { addEmitter?: any }>();
    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadSlim(engine);
            await loadEmittersPlugin(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const particlesOptions = useMemo(
        () => ({
            fullScreen: { enable: false, zIndex: 9999 },
            interactivity: {
                detectsOn: InteractivityDetect.window,
                events: {
                    onClick: {
                        enable: true,
                        mode: 'emitter',
                    },
                },
                modes: {
                    emitters: {
                        life: {
                            count: 1,
                            duration: 0.1,
                            delay: 0.6,
                        },
                        rate: {
                            delay: 0.1,
                            quantity: 200,
                        },
                        size: {
                            width: 1,
                            height: 1,
                        },
                        direction: 'none',
                        angle: {
                            value: {
                                min: 0,
                                max: 180, // Particles emit in all directions
                            },
                        },
                    },
                },
            },
            particles: {
                number: { value: 0 },
                color: { value: ['#fff'] }, // Particle colors
                shape: { type: 'circle' },
                opacity: {
                    value: 1,
                    random: false,
                    animation: {
                        enable: true,
                        speed: 2,
                        minimumValue: 0,
                        sync: false,
                    },
                },
                size: {
                    value: { min: 1, max: 3 },
                    animation: {
                        enable: true,
                        speed: 2,
                        minimumValue: 0,
                        sync: false,
                    },
                },
                life: {
                    duration: {
                        sync: true,
                        value: 1,
                    },
                    count: 1,
                },
                move: {
                    decay: 0.04,
                    enable: true,
                    gravity: {
                        enable: true,
                        acceleration: 40, // Particles fall down
                    },
                    speed: { min: 30, max: 50 },
                    angle: { offset: 0, value: 90 },
                    random: true,
                    straight: true,
                    outModes: OutMode.destroy,
                },
            },
            emitters: [],
        }),
        []
    );

    const particlesLoaded = useCallback(async (container?: Container) => {
        if (container) {
            particlesContainerRef.current = container;
        }
    }, []);

    return (
        <>
            {init && (
                <Particles
                    id="particle-container"
                    className={styles.particleContainer}
                    options={particlesOptions}
                    particlesLoaded={particlesLoaded}
                />
            )}
        </>
    );
});

export default ParticlesComponent;
